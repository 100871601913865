import Axios, { AxiosResponse } from "axios";
import store from "@/store";
import { Configuration } from "@/configuration";
import { Project, ProjectInterface } from "@/models/Project";
import { DniTrim } from "@/utils";
import api from '@/api';

export const ErrorHandler = (res: AxiosResponse, data: any) => {
  return data;
};

Axios.defaults.headers["Content-Type"] = "application/json";

export default {
  getEstados() {
    return new Promise((resolve, reject) => {
      Axios.get(`${Configuration.api.base}/proyecto/estados`, {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then((res: any) => {
          resolve(res.data.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  registraranalytics( 
    origen: string,
    ip_address: string,
    variable:string,
    user_id: any){
    return new Promise((resolve, reject) => {
      Axios.post(`${Configuration.api.base}/tracking/track-create`,{
        origen,
        ip_address,
        variable,
        user_id
      },
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then((res: any) => {
          resolve(res.data.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getGateways() {
    return new Promise((resolve, reject) => {
      Axios.get(`${Configuration.api.base}/pasarelas`, {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then((res: any) => {
          resolve(res.data.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getVIP(id: any) {
    return new Promise((resolve, reject) => {
      Axios.get(`${Configuration.api.base}/usuario/vip/${id}`, {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then((res: any) => {
          resolve(res.data.message);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  admin: {
    getAllUsers() {
      return new Promise((resolve, reject) => {
        // @ts-ignore
        Axios.get(`${Configuration.api.base}/usuario`, {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        })
          .then(res => {
            resolve(res.data.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    loginBypass(id: any) {
      return new Promise((resolve, reject) => {
        Axios.post(
          `${Configuration.api.base}/bypassLogin/${id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }
        )
          .then(res => {
            resolve(res.data.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  },
  khipu: {
    confirm: {
      normal(transaction: any) {
        return new Promise((resolve, reject) => {
          Axios.post(
            `${Configuration.api.base}/khipu/confirmar`,
            {
              inversion_id: transaction.inversion_id,
              transaccion_id: transaction.id,
              monto: transaction.monto,
              cod: transaction.cod
            },
            {
              headers: {
                Authorization: `Bearer ${store.state.token}`
              }
            }
          )
            .then(res => {
              resolve(res.data);
            })
            .catch(error => {
              reject(error);
            });
        });
      },
      pending(transaction: any) {
        return new Promise((resolve, reject) => {
          Axios.post(
            `${Configuration.api.base}/khipu/confirmar-pago-pendiente`,
            {
              inversion_id: transaction.inversion_id,
              transaccion_id: transaction.id,
              monto: transaction.monto,
              cod_transaccion: transaction.transaccion,
              pago_deta_tipo_id: transaction.pago_deta_tipo_id,
              cod_inversion: transaction.cod
            },
            {
              headers: {
                Authorization: `Bearer ${store.state.token}`
              }
            }
          )
            .then(res => {
              resolve(res.data);
            })
            .catch(error => {
              reject(error);
            });
        });
      }
    }
  },
  payment: {
    create(payload: any) {
      return new Promise((resolve, reject) => {
        Axios.post(
          `${Configuration.api.base}/transaccion/crear`,
          {
            // @ts-ignore
            user_id: store.state.user.id,
            proyecto_id: payload.projectId,
            motivo: payload.subject,
            // @ts-ignore
            monto: store.state.user.id == 3818 ? 1000 : payload.amount,
            reserva: payload.isReserving,
            socio: payload.isJoiningClub,
            es_socio: payload.isPartner,
            pasarela_id: payload.paymentGateway,
            inversion_id: payload.investmentId,
            pago_deta_tipo_id: payload.paymentType,
            return_url: window.location.origin + "/gracias-por-invertir",
            cancel_url:
              window.location.origin +
              "/panel/inversionista/transferencias-pendientes",
            // @ts-ignore
            payer_email: store.state.user.email,
            // @ts-ignore
            payer_name: store.state.user.nombre + " " + store.state.user.app_pat
          },
          {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }
        )
          .then(res => {
            resolve(res.data.data);
          })
          .catch(error => {
            reject(error.response.data);
          });
      });
    },
    types() {
      return new Promise((resolve, reject) => {
        Axios.get(`${Configuration.api.base}/pagos/detalle/tipos`, {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        })
          .then(res => {
            resolve(res.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    details(investmentId: number) {
      return new Promise((resolve, reject) => {
        Axios.get(`${Configuration.api.base}/pagos/${investmentId}`, {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        })
          .then(res => {
            resolve(res.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    report: {
      gateway(projectId: number, amount: number, payload: any) {
        return new Promise((resolve, reject) => {
          Axios.post(
            `${Configuration.api.base}/pagos/crear-inversionista`,
            {
              // @ts-ignore
              user_id: store.state.user.id,
              inversion_id: payload.id,
              proyecto_id: projectId,
              monto: amount,
              nombre_titular: payload.name,
              cedula_titular: payload.dni,
              fecha: payload.date,
              pasarela_id: payload.gatewayId,
              cod_transaccion: payload.code,
              pago_deta_tipo_id: 1
            },
            {
              headers: {
                Authorization: `Bearer ${store.state.token}`
              }
            }
          )
            .then(res => {
              resolve(res.data);
            })
            .catch(error => {
              reject(error);
            });
        });
      },
      tracking(tipo: any, id: any) {
        return new Promise((resolve, reject) => {
          Axios.put(
            `${Configuration.api.base}/tracking-inversion/eleccion-pago`,
            {
              tipo: tipo,
              inversion_id: id
            },
            {
              headers: {
                Authorization: `Bearer ${store.state.token}`
              }
            }
          )
            .then(res => {
              resolve(res.data);
            })
            .catch(error => {
              reject(null);
            });
        });
      },
      transference(projectId: number, amount: number, payload: any) {
        return new Promise((resolve, reject) => {
          Axios.post(
            `${Configuration.api.base}/pagos/crear-transferencia-inversionista`,
            {
              // @ts-ignore
              user_id: store.state.user.id,
              proyecto_id: projectId,
              pasarela_id: 1,
              cod_transaccion: payload.cod,
              // monto: amount,
              // @ts-ignore
              monto: store.state.user.id == 3818 ? 1000 : amount,
              inversion_id: payload.investmentId,
              banco_id: payload.bank,
              nro_cuenta: payload.accountNumber,
              nombre_titular: payload.name,
              cedula_titular: payload.dni,
              fecha: payload.date,
              pago_deta_tipo_id: payload.paymentType
            },
            {
              headers: {
                Authorization: `Bearer ${store.state.token}`
              }
            }
          )
            .then(res => {
              resolve(res.data);
            })
            .catch(error => {
              reject(error);
            });
        });
      }
    },
    pending: {
      transfer(projectId: number, payload: any) {
        return new Promise((resolve, reject) => {
          Axios.post(
            `${Configuration.api.base}/pagos/crear-transferencia-inversionista`,
            {
              // @ts-ignore
              user_id: store.state.user.id,
              proyecto_id: projectId,
              // @ts-ignore
              monto: store.state.user.id == 3818 ? 1000 : payload.amount,
              // monto: payload.amount,
              inversion_id: payload.investmentId,
              banco_id: payload.bank,
              nro_cuenta: payload.accountNumber,
              nombre_titular: payload.name,
              cedula_titular: payload.dni,
              fecha: payload.date,
              pago_deta_tipo_id: payload.paymentType
            },
            {
              headers: {
                Authorization: `Bearer ${store.state.token}`
              }
            }
          )
            .then(res => {
              resolve(res.data);
            })
            .catch(error => {
              reject(error);
            });
        });
      },
      gateway(payload: any) {
        return new Promise((resolve, reject) => {
          Axios.post(
            `${Configuration.api.base}/transaccion/crear-pago-pendiente-khipu`,
            {
              inversion_id: payload.investmentId,
              monto: payload.amount,
              pago_deta_tipo_id: payload.paymentType,
              transaccion: payload.transaction,
              motivo: payload.subject,
              return_url: window.location.origin + "/gracias-por-invertir",
              cancel_url: window.location.origin + "/pago-erroneo",
              // @ts-ignore
              payer_email: store.state.user.email,
              // @ts-ignore
              payer_name:
                // @ts-ignore
                store.state.user.nombre + " " + store.state.user.app_pat
            },
            {
              headers: {
                Authorization: `Bearer ${store.state.token}`
              }
            }
          )
            .then(res => {
              resolve(res.data);
            })
            .catch(error => {
              reject(error);
            });
        });
      }
    }
  },
  investment: {
    normal(paymentId: number) {
      return new Promise((resolve, reject) => {
        Axios.get(`${Configuration.api.base}/inversion/${paymentId}`, {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        })
          .then(res => {
            resolve(res.data.data);
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    pending(paymentId: number) {
      return new Promise((resolve, reject) => {
        Axios.get(`${Configuration.api.base}/inversion/${paymentId}`, {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        })
          .then(res => {
            resolve(res.data);
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    temporal: {
      create() {},
      delete() {},
      all() {}
    },
    initial: {
      create() {},
      all() {}
    },
    all() {}
  },
  user: {
    account: {
      get() {},
      update() {},
      password: {
        update() {}
      }
    },
    terms: {
      get() {}
    }
  },
  dashboard: {
    montoTotalInvertido(id:any) {
      return new Promise((resolve, reject) => {
        // @ts-ignore
        Axios.get(
          // @ts-ignore
          `${Configuration.api.base}/estadisticas/montoTotalInvertido/${store.state.user.id}/${id}`,
          {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }
        )
          .then(res => {
            resolve(res.data.data[0].monto_invertido);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    montoTotalRecibidoProyectoCerrado(id:any) {
      return new Promise((resolve, reject) => {
        // @ts-ignore
        Axios.get(
          // @ts-ignore
          `${Configuration.api.base}/estadisticas/montoTotalRecibidoProyectoCerrado/${store.state.user.id}/${id}`,
          {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }
        )
          .then(res => {
            resolve(res.data.data[0].monto);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    montoTotalInvertidoPendienteDePago(id:any) {
      return new Promise((resolve, reject) => {
        // @ts-ignore
        Axios.get(
          // @ts-ignore
          `${Configuration.api.base}/estadisticas/montoTotalInvertidoPendienteDePago/${store.state.user.id}/${id}`,
          {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }
        )
          .then(res => {
            resolve(res.data.data[0].monto_invertido);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    rentabilidadProyectosPagadosCerrados(id: any) {
      return new Promise((resolve, reject) => {
        // @ts-ignore
        Axios.get(
          // @ts-ignore
          `${Configuration.api.base}/estadisticas/rentabilidadProyectosPagadosCerrados/${store.state.user.id}/${id}`,
          {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }
        )
          .then(res => {
            resolve(res.data.data[0].monto);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    rentabilidadProyectosMoraMayor180DiasImpagas(id: any) {
      return new Promise((resolve, reject) => {
        // @ts-ignore
        Axios.get(
          // @ts-ignore
          `${Configuration.api.base}/estadisticas/rentabilidadProyectosMoraMayor180DiasImpagas/${store.state.user.id}/${id}`,
          {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }
        )
          .then(res => {
            resolve(res.data.data[0]);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    totalMontosInvertidoProyectosActivos(id: any) {
      return new Promise((resolve, reject) => {
        // @ts-ignore
        Axios.get(
          // @ts-ignore
          `${Configuration.api.base}/estadisticas/totalMontosInvertidoProyectosActivos/${store.state.user.id}/${id}`,
          {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }
        )
          .then(res => {
            resolve(res.data.data[0].monto);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    montoInvertidoEnMora(projectId: any) {
      return new Promise((resolve, reject) => {
        // @ts-ignore
        Axios.get(
          // @ts-ignore
          `${Configuration.api.base}/estadisticas/montoInvertidoEnMora/${store.state.user.id}/${projectId}`,
          {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }
        )
          .then(res => {
            resolve(res.data.data.monto);
          })
          .catch(error => {
            reject(null);
          });
      });
    },
    montoEnMora180Dias() {
      return new Promise((resolve, reject) => {
        // @ts-ignore
        Axios.get(
          // @ts-ignore
          `${Configuration.api.base}/estadisticas/montoEnMora180Dias/${store.state.user.id}`,
          {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }
        )
          .then(res => {
            resolve(res.data.data.monto);
          })
          .catch(error => {
            reject(null);
          });
      });
    },
    tasaMontoEnMora180Dias() {
      return new Promise((resolve, reject) => {
        // @ts-ignore
        Axios.get(
          // @ts-ignore
          `${Configuration.api.base}/estadisticas/tasaMontoEnMora180Dias/${store.state.user.id}`,
          {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }
        )
          .then(res => {
            resolve(res.data.data);
          })
          .catch(error => {
            reject(null);
          });
      });
    }
  },
  // START ESTADISTICAS
  getTotalAmountInvested(id : any) {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Axios.get(
        // @ts-ignore
        `${Configuration.api.base}/montoTotalInvertido/${store.state.user.id}/${id}`,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getTotalAmountInvestedFromClosedProjects() {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Axios.get(
        // @ts-ignore
        `${Configuration.api.base}/montoTotalInvertidoProyectoCerrado/${store.state.user.id}`,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getTotalAmountReceivedFromClosedProjects(id: any) {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Axios.get(
        // @ts-ignore
        `${Configuration.api.base}/montoTotalRecibidoProyectoCerrado/${store.state.user.id}/${id}`,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getTotalUtilityEarnedFromClosedProjects() {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Axios.get(
        // @ts-ignore
        `${Configuration.api.base}/gananciaTotalProyectoCerrado/${store.state.user.id}`,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getRateOfThreeMonths() {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Axios.get(
        // @ts-ignore
        `${Configuration.api.base}/montosMoraTresMeses/${store.state.user.id}`,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getRateOfSixMonths() {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Axios.get(
        // @ts-ignore
        `${Configuration.api.base}/montosMoraSeisMeses/${store.state.user.id}`,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data.data.monto_mora);
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  getRateOfThirtyDays() {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Axios.get(
        // @ts-ignore
        `${Configuration.api.base}/vencimientoTreintaDias/${store.state.user.id}`,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data.data.monto_por_vencer);
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  getRateOfSixtyDays() {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Axios.get(
        // @ts-ignore
        `${Configuration.api.base}/vencimientoSesentaDias/${store.state.user.id}`,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data.data.monto_por_vencer);
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  getBeatenRate() {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Axios.get(
        // @ts-ignore
        `${Configuration.api.base}/montoVencidoMora/${store.state.user.id}`,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data.data.monto_vencido_mora);
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  getBeatenBalance() {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Axios.get(
        // @ts-ignore
        `${Configuration.api.base}/saldoInsoluto/${store.state.user.id}`,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data.data);
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  // END ESTADISTICAS
  getAmounts() {
    return new Promise((resolve, reject) => {
      Axios.get(`${Configuration.api.base}/montos`)
        .then(res => {
          resolve(res.data.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  getTransferences() {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Axios.get(
        // @ts-ignore
        `${Configuration.api.base}/transferencias/${store.state.user.id}`,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data.data);
        })
        .catch(error => {
          reject(error.response);
        });
    });
  },
  getInitialInvestments(projectId: any) {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Axios.get(
        // @ts-ignore
        `${Configuration.api.base}/inversiones/iniciales/${store.state.user.id}/${projectId}`,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  createInitialInvestment(payload: any) {
    return new Promise((resolve, reject) => {
      Axios.post(
        `${Configuration.api.base}/inversiones/crear`,
        {
          // @ts-ignore
          user_id: store.state.user.id,
          proyecto_id: payload.projectId,
          // @ts-ignore
          monto: store.state.user.id == 3818 ? 1000 : payload.amount,
          // inversion: payload.investment,
          reserva: payload.isReserving,
          cancelando_socio: payload.isCancellingClub,
          es_socio: payload.isPartner,
          socio: payload.isJoiningClub,
          inversion_tmp_id: payload.investmentId,
          cod_inversion: payload.code
        },
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getTemporalInvestmentsByUserId() {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Axios.get(
        // @ts-ignore
        `${Configuration.api.base}/inversion-tmp/listado/${store.state.user.id}`,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getTemporalInvestments(projectId: any) {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Axios.get(
        // @ts-ignore
        `${Configuration.api.base}/inversion-tmp/listado/${store.state.user.id}/${projectId}`,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  createTemporalInvestment(tmp: any) {
    return new Promise((resolve, reject) => {
      Axios.post(
        `${Configuration.api.base}/inversion-tmp/crear`,
        {
          // @ts-ignore
          user_id: store.state.user.id,
          proyecto_id: tmp.project_id,
          // @ts-ignore
          monto: store.state.user.id == 3818 ? 1000 : tmp.amount
        },
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  deleteTemporalInvestments(projectId: number) {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Axios.delete(`${Configuration.api.base}/inversion-tmp/eliminar`, {
        data: {
          // @ts-ignore
          user_id: store.state.user.id,
          proyecto_id: projectId
        },
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getClub() {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Axios.get(`${Configuration.api.base}/socio/${store.state.user.id}`, {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then(res => {
          resolve(res.data.data);
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  getSecretProjects(): Promise<Array<Project>> {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Axios.get(`${Configuration.api.base}/secretos/${store.state.user.id}`, {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then(res => {
          let projects: Array<ProjectInterface> = res.data.data.proyectos;
          resolve(projects.map(project => new Project(project)));
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  getProjects(data: any): Promise<Array<Project>> {
    return new Promise((resolve, reject) => {
      Axios.post(
        `${Configuration.api.base}/filtrado`,
        {
          factura: data.factura ? 1 : 0,
          credito: data.credito ? 1 : 0,
          tasa: data.tasa,
          cuota: data.cuota,
          offset: data.offset
        },
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          let projects: Array<ProjectInterface> = res.data;
          if (process.env.VUE_APP_API_ENV == "development") {
            resolve(projects.map(project => new Project(project)));
          } else {
            resolve(
              projects
                .map(project => new Project(project))
                .filter(pro => pro.id != 1)
            );
          }
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  getStatistics(projectId: number) {
    return new Promise((resolve, reject) => {
      Axios.get(`${Configuration.api.base}/proyectoEstadistica/${projectId}`, {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then(res => {
          resolve(res.data.data[1]);
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  getProject(projectId: number): Promise<ProjectInterface> {
    return new Promise((resolve, reject) => {
      Axios.get(`${Configuration.api.base}/proyecto/${projectId}`, {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then(res => {
          let project: ProjectInterface = res.data.data;
          // @ts-ignore
          resolve(new Project(project));
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  getSecretProject(projectId: number): Promise<ProjectInterface> {
    return new Promise((resolve, reject) => {
      Axios.get(`${Configuration.api.base}/proyecto/secreto/${projectId}`, {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then(res => {
          let project: ProjectInterface = res.data.data;
          // @ts-ignore
          resolve(new Project(project));
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  addProjectView(projectId: number) {
    return new Promise((resolve, reject) => {
      Axios.post(`${Configuration.api.base}/proyecto/visita`, {
        proyecto_id: projectId
      })
        .then(res => {
          resolve(res);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getProjectAmounts(projectId: number) {
    return new Promise((resolve, reject) => {
      Axios.get(
        `${Configuration.api.base}/proyecto-montos-total/${projectId}`,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  simulate(projectId: number, amount: number, last: any, id?: any) {
    return new Promise((resolve, reject) => {
      Axios.post(
        `${Configuration.api.base}/simular`,
        {
          proyecto_id: projectId,
          monto: amount,
          user: id,
          ultimo: last
        },
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data.data);
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  login(rut: string, password: string) {
    return new Promise((resolve, reject) => {
      Axios.post(`${Configuration.api.base}/login`, {
        rut: DniTrim.string(rut),
        password: password
      })
        .then(res => {
          resolve(res.data.data);
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  register(
    nombre: string,
    app_pat: string,
    email: string,
    password: string,
    c_password: string,
    dni: string,
    telefono: string,
    user_tipo_id: number
  ) {
    return new Promise((resolve, reject) => {
      Axios.post(`${Configuration.api.base}/register`, {
        nombre: nombre,
        app_pat: app_pat,
        email: email,
        password: password,
        c_password: c_password,
        rut: DniTrim.string(dni),
        telefono: telefono,
        user_tipo_id: user_tipo_id
      })
        .then(res => {
          resolve(res);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  logout() {
    return new Promise((resolve, reject) => {
      Axios.get(`${Configuration.api.base}/logout`, {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then(res => {
          console.log(res)
          resolve(res);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getBanks() {
    return new Promise((resolve, reject) => {
      Axios.get(`${Configuration.api.base}/banco`, {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then(res => {
          resolve(res.data.data);
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  getAccountTypes() {
    return new Promise((resolve, reject) => {
      Axios.get(`${Configuration.api.base}/bancos/tipos`, {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then(res => {
          resolve(res.data.data);
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  getCompany(companyId: number) {
    return new Promise((resolve, reject) => {
      Axios.get(`${Configuration.api.base}/empresa/${companyId}`, {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then(res => {
          resolve(res.data.data);
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  getCountries() {
    return new Promise((resolve, reject) => {
      Axios.get(`${Configuration.api.base}/pais`, {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then(res => {
          resolve(res.data.data);
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  getDeparments() {
    // /${provinceId}
    return new Promise((resolve, reject) => {
      Axios.get(`${Configuration.api.base}/listar_departamentos`, {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  getProvinces(departmentId: number) {
    return new Promise((resolve, reject) => {
      Axios.get(`${Configuration.api.base}/listar_provincias/${departmentId}`, {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  getDistricts(provinceId?: number) {
    return new Promise((resolve, reject) => {
      Axios.get(`${Configuration.api.base}/listar_distrito/${provinceId}`, {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  contact(name: string, mail: string, telefono: string, message: string, clasificacion_id? : number) {
    return new Promise((resolve, reject) => {
      Axios.post(
        `${Configuration.api.base}/contacto`,
        {
          /* nombre: name,
          mail: mail,
          mensaje: message */
          nombre: name,
          mail: mail,
          telefono,
          mensaje: message,
          clasificacion_id
        },
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          resolve(error.response.data);
        });
    });
  },
  recover(rut: string) {
    return new Promise((resolve, reject) => {
      Axios.post(
        `${Configuration.api.base}/password/create`,
        {
          rut
        },
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  newPassword(
    rut: string,
    password: string,
    c_password: string,
    token: string
  ) {
    return new Promise((resolve, reject) => {
      Axios.post(
        `${Configuration.api.base}/password/reset`,
        {
          rut: DniTrim.string(rut),
          password: password,
          c_password: c_password,
          token: token
        },
        {
          headers: {}
        }
      )
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  credit(creditPayload: any) {
    return new Promise((resolve, reject) => {
      Axios.post(
        `${Configuration.api.base}/credito/solicitar`,
        {
          ruc: DniTrim.string(creditPayload.ruc),
          empresa: creditPayload.name,
          telefono: creditPayload.phone,
          email: creditPayload.email,
          credito_objetivo_id: creditPayload.clients,
          tipo_contacto_id: creditPayload.tipo_contacto_id,
          nombre_contacto: creditPayload.nombre_contacto,
        },
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data.data);
        })
        .catch(error => {
          reject(error.response);
        });
    });
  },
  createBusiness(payload: any) {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Axios.post(`${Configuration.api.base}/empresa`, payload, {
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${store.state.token}`
        }
        /* onUploadProgress: callback, */
      })
        .then((res: any) => {
          resolve(res.data.data);
        })
        .catch((error: any) => {
          reject(error.response);
        });
    });
  },
  editCompany(company: any) {
    return new Promise((resolve, reject) => {
      let excludes = [
        "id",
        "ejecutivo_id",
        "credito_venta_id",
        "imagen_id",
        "fecha_creacion",
        "deuda",
        "afecto_iva",
        "carpeta"
      ];

      let payload: any = {};

      for (let key in company) {
        if (key.endsWith("ed_at") || excludes.includes(key)) continue;

        payload[key] = company[key];
      }

      Axios.put(`${Configuration.api.base}/empresa/${company.id}`, payload, {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then(res => {
          resolve(true);
        })
        .catch(error => {
          reject(false);
        });
    });
  },
  requestsByCompanyId(empresa_id: number) {
    return new Promise((resolve, reject) => {
      Axios.get(`${Configuration.api.base}/solicitudes-empresa/${empresa_id}`, {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then(res => {
          resolve(res.data.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  projectsByCompanyId(empresa_id: number) {
    return new Promise((resolve, reject) => {
      Axios.get(`${Configuration.api.base}/proyectos-empresa/${empresa_id}`, {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then(res => {
          resolve(res.data.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  request: {
    factoring(empresa_id: string, facturas: any[]) {
      return new Promise((resolve, reject) => {
        let payload: any = {
          // @ts-ignore
          user_id: store.state.user.id,
          empresa_id,
          facturas
        };
        Axios.post(`${Configuration.api.base}/solicitud/factoring`, payload, {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        })
          .then(res => {
            resolve(res.data.data);
          })
          .catch(error => {
            reject(error.response.data);
          });
      });
    },
    credit(
      empresa_id: string,
      motivo: string,
      monto: string,
      plazo: number,
      garantia: any
    ) {
      return new Promise((resolve, reject) => {
        let payload: any = {
          // @ts-ignore
          user_id: store.state.user.id,
          empresa_id,
          motivo,
          monto,
          plazo
        };

        if (garantia != null) {
          payload["garantia"] = garantia;
        }

        Axios.post(`${Configuration.api.base}/solicitud/credito`, payload, {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        })
          .then(res => {
            resolve(res.data.data);
          })
          .catch(error => {
            reject(error.response.data);
          });
      });
    },
    leaseback(
      empresa_id: string,
      motivo: string,
      monto: string,
      plazo: number,
      garantia?: any
    ) {
      return new Promise((resolve, reject) => {
        Axios.post(
          `${Configuration.api.base}/solicitud/leaseback`,
          {
            // @ts-ignore
            user_id: store.state.user.id,
            empresa_id,
            motivo,
            monto,
            plazo,
            garantia
          },
          {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }
        )
          .then(res => {
            resolve(res.data.data);
          })
          .catch(error => {
            reject(error.response.data);
          });
      });
    },
    accion(
      empresa_id: string,
      motivo: string,
      monto: string,
      plazo: number,
      garantia: any,
      accion_pyme_id?: any
    ) {
      return new Promise((resolve, reject) => {
        let payload: any = {
          // @ts-ignore
          user_id: store.state.user.id,
          empresa_id,
          motivo,
          monto,
          plazo,
          accion_pyme_id
        };

        Axios.post(`${Configuration.api.base}/solicitud/credito`, payload, {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        })
          .then(res => {
            resolve(res.data.data);
          })
          .catch(error => {
            reject(error.response.data);
          });
      });
    }
  },
  requestCredit(
    empresa_id: string,
    type_id: string,
    rut: string,
    monto: number,
    nombre: string,
    plazo: number,
    garantia_id?: number,
    motivo?: string
  ) {
    return new Promise((resolve, reject) => {
      let payload: any = {
        // @ts-ignore
        user_id: store.state.user.id,
        empresa_id: empresa_id,
        tipo_id: type_id,
        rut,
        garantia_id,
        monto,
        nombre,
        plazo,
        motivo
        // facturas: [
        //   {
        //     folio,
        //     rut,
        //     monto,
        //     nombre,
        //     plazo,
        //   },
        // ],
        // garantia: {
        //   monto,
        //   garantia_id,
        //   plazo,
        //   valor_garantia,
        //   deuda_garantia,
        // }
      };

      Axios.post(`${Configuration.api.base}/solicitud`, payload, {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then(res => {
          resolve(res.data.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  getMyInvestments(): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Axios.get(
        // @ts-ignore
        `${Configuration.api.base}/inversiones/${store.state.user.id}`,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data.data[0]);
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  getMyStates(): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Axios.get(
        // @ts-ignore
        `${Configuration.api.base}/inversiones/${store.state.user.id}`,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data.data[1]);
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  getUserBank(id:any) {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Axios.get(
        // @ts-ignore
        `${Configuration.api.base}/usuario/banco/${id}`,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          if (res.data.data.cuenta_bancaria != null) {
            resolve(res.data.data.cuenta_bancaria);
          } else {
            reject(null);
          }
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  updateBankAccount(
    accountNumber: string,
    bank_id: number,
    bankAccountType: number
  ) {
    return new Promise((resolve, reject) => {
      Axios.post(
        `${Configuration.api.base}/usuario/banco`,
        {
          // @ts-ignore
          numero: accountNumber,
          banco_id: bank_id,
          tipo_cuenta_id: bankAccountType
        },
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve({
            message: "ok"
          });
        })
        .catch(error => {
          reject({
            error: "No se que tipo de error poner"
          });
        });
    });
  },
  getPendingPayments(): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Axios.get(
        // @ts-ignore
        `${Configuration.api.base}/inversiones/${store.state.user.id}/pendiente`,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          let payments: Array<any> = res.data.data;
          resolve(payments);
        })
        .catch(error => {
          reject([]);
        });
    });
  },
  AnularPendingPayments(id:any,comentarios:any){
    return new Promise((resolve, reject)=>{
      Axios.post(`${Configuration.api.base}/inversiones/inversionista_anula`, {
        inversion_proyecto_id: id,
        texto:comentarios
      }, {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      }).then(res=>{
        resolve(res)
      }).catch(error => {reject(error)})
    })
  },
  documents: {
    contracts() {
      return new Promise((resolve, reject) => {
        // @ts-ignore
        Axios.get(
          // @ts-ignore
          `${Configuration.api.base}/documentos/${store.state.user.id}/contratos`,
          {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }
        )
          .then(res => {
            if (res.data.errors != undefined) {
              resolve([]);
            } else {
              resolve(res.data.data.contratos);
            }
          })
          .catch(error => {
            reject(error.response.data);
          });
      });
    },
    terms() {
      return new Promise((resolve, reject) => {
        // @ts-ignore
        Axios.get(
          // @ts-ignore
          `${Configuration.api.base}/documentos/${store.state.user.id}/mandatos`,
          {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }
        )
          .then(res => {
            if (res.data.errors != undefined) {
              resolve([]);
            } else {
              resolve(res.data.data.contratos);
            }
          })
          .catch(error => {
            reject(null);
          });
      });
    },
    ious() {
      return new Promise((resolve, reject) => {
        // @ts-ignore
        Axios.get(
          // @ts-ignore
          `${Configuration.api.base}/documentos/${store.state.user.id}/pagares`,
          {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }
        )
          .then(res => {
            if (res.data.errors != undefined) {
              resolve([]);
            } else {
              resolve(res.data.data.contratos);
            }
          })
          .catch(error => {
            reject(null);
          });
      });
    }
  },
  getUniqueCode() {
    return new Promise((resolve, reject) => {
      Axios.get(`${Configuration.api.base}/codigo`).then(res => {
        resolve(res.data);
      });
    });
  },
  updatePersonalData(user: any) {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      // console.log(store.state.token)
      Axios.put(
        // @ts-ignore
        `${Configuration.api.base}/usuario/${store.state.user.id}`,
        user,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  getPersonalData(userId: number) {
    return new Promise((resolve, reject) => {
      Axios.get(`${Configuration.api.base}/usuario/${userId}`, {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then(res => {
          resolve(res.data.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getDistrito_id(distrito_id: number){
    return new Promise((resolve, reject) => {
      Axios.get(`${Configuration.api.base}/list_distrito_prov_depto/${distrito_id}`, {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then(res => {
          resolve(res);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  changePassword(
    oldPassword: string,
    newPassword: string,
    confirmPassword: string
  ) {
    return new Promise((resolve, reject) => {
      Axios.post(
        `${Configuration.api.base}/usuario/password`,
        {
          password: oldPassword,
          password_new: newPassword,
          password_c: confirmPassword
        },
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getBusiness() {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Axios.get(
        // @ts-ignore
        `${Configuration.api.base}/empresa/usuario/${store.state.user.id}`,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data.data.empresas);
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  getQuote(amount: number, month: number) {
    return new Promise((resolve, reject) => {
      Axios.post(
        `${Configuration.api.base}/simulacion/accion`,
        {
          capital: amount,
          tiempo_meses: month
        },
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  actionSimulate(simulate: any) {
    return new Promise((resolve, reject) => {
      Axios.post(
        `${Configuration.api.base}/simulacion/accion/email`,
        {
          monto: simulate.amount,
          email: simulate.mail,
          cuota: simulate.quote,
          plazo: simulate.month
        },
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  landing: {
    chilecompra(
      name: string,
      mail: string,
      message: string,
      phone: string,
      rut_empresa: any,
      nombre_empresa: any
    ) {
      return new Promise((resolve, reject) => {
        Axios.post(
          `${Configuration.api.base}/landing/chilecompra`,
          {
            nombre: name,
            mail: mail,
            rut_empresa: DniTrim.string(rut_empresa),
            nombre_empresa: nombre_empresa,
            mensaje: message,
            telefono: phone
          },
          {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }
        )
          .then(res => {
            resolve(res.data);
          })
          .catch(error => {
            reject(error.response.data);
          });
      });
    },
    landing(name: string, mail: string, phone: string, via: string) {
      return new Promise((resolve, reject) => {
        Axios.post(`${Configuration.api.base}/landingcrm`, {
          nombre: name,
          email: mail,
          telefono: phone,
          via: via
        })
          .then(res => {
            resolve(res);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    santander(
      name: string,
      lastname: string,
      dni: any,
      businessName: string,
      businessDni: any,
      mail: string,
      landing: string,
      via: string,
      phone?: string,
      amount?: number,
      comment?: string
    ) {
      return new Promise((resolve, reject) => {
        Axios.post(`${Configuration.api.base}/landingSolicitantes`, {
          nombre: name,
          apellido: lastname,
          rut: DniTrim.string(dni),
          nombre_empresa: businessName,
          rut_empresa: DniTrim.string(businessDni),
          telefono: phone,
          email: mail,
          landing: landing,
          via: via,
          monto_factura: amount,
          comentario_banco: comment
        })
          .then(res => {
            resolve(res);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    bcp(
      name: string,
      lastname: string,
      dni: any,
      businessName: string,
      businessDni: any,
      mail: string,
      landing: string,
      via: string,
      phone?: string,
      amount?: number,
      comment?: string
    ) {
      return new Promise((resolve, reject) => {
        Axios.post(`${Configuration.api.base}/landingSolicitantes`, {
          nombre: name,
          apellido: lastname,
          rut: DniTrim.string(dni),
          nombre_empresa: businessName,
          rut_empresa: DniTrim.string(businessDni),
          telefono: phone,
          email: mail,
          landing: landing,
          via: via,
          monto_factura: amount,
          comentario_banco: comment
        })
          .then(res => {
            resolve(res);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    banigualdad(
      name: string,
      lastname: string,
      dni: any,
      businessName: string,
      businessDni: any,
      mail: string,
      landing: string,
      via: string,
      phone?: string,
      amount?: number,
      comment?: string
    ) {
      return new Promise((resolve, reject) => {
        Axios.post(`${Configuration.api.base}/landingSolicitantes`, {
          nombre: name,
          apellido: lastname,
          rut: DniTrim.string(dni),
          nombre_empresa: businessName,
          rut_empresa: DniTrim.string(businessDni),
          telefono: phone,
          email: mail,
          landing: landing,
          via: via,
          monto_factura: amount,
          comentario_banco: comment
        })
          .then(res => {
            resolve(res);
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  },
  customContact(
    name: string,
    mail: string,
    message: string,
    phone: string,
    rut_empresa: any,
    nombre_empresa: any,
    landing: any,
    type: any
  ) {
    return new Promise((resolve, reject) => {
      Axios.post(
        `${Configuration.api.base}/landing`,
        {
          nombre: name,
          mail: mail,
          rut_empresa: DniTrim.string(rut_empresa),
          nombre_empresa: nombre_empresa,
          mensaje: message,
          telefono: phone,
          landing: landing,
          clasificacion_id: type
        },
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  chilecompra(
    name: string,
    mail: string,
    message: string,
    phone: string,
    type: any
  ) {
    return new Promise((resolve, reject) => {
      Axios.post(
        `${Configuration.api.base}/contacto`,
        {
          nombre: name,
          mail: mail,
          mensaje: message,
          telefono: phone,
          clasificacion_id: type
        },
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  scheduleCall(message: string, phone: string) {
    return new Promise((resolve, reject) => {
      Axios.post(
        `${Configuration.api.base}/contacto`,
        {
          // @ts-ignore
          nombre: `${store.state.user.nombre} ${store.state.user.app_pat}`,
          // @ts-ignore
          mail: store.state.user.email,
          mensaje: message,
          telefono: phone,
          clasificacion_id: 1
        },
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          resolve(error.response.data);
        });
    });
  },
  declarationes(user_id:number){
    return new Promise((resolve,reject)=>{
      Axios.get(`${Configuration.api.base}/documentos/${user_id}/declaraciones`,{
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then(res => {
          resolve(res);
        })
        .catch(error => {
          reject(error);
        });
    })
  },
  declaration(user_id:number, anio:any){
    return new Promise((resolve,reject)=>{
      Axios.get(`${Configuration.api.base}/documentos/${user_id}/declaracion/${anio}`,{
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then(res => {
          resolve(res);
        })
        .catch(error => {
          reject(error);
        });
    })
  },
  accion: {
    eleven: {
      howMuch() {
        return new Promise((resolve, reject) => {
          // @ts-ignore
          Axios.get(`${Configuration.api.base}/cuanto_vendes`, {})
            .then(res => {
              resolve(res.data.data);
            })
            .catch(error => {
              reject(null);
            });
        });
      },
      anualSales() {
        return new Promise((resolve, reject) => {
          // @ts-ignore
          Axios.get(`${Configuration.api.base}/ventas_anio_anterior`, {})
            .then(res => {
              resolve(res.data.data);
            })
            .catch(error => {
              reject(null);
            });
        });
      },
      nextYear() {
        return new Promise((resolve, reject) => {
          // @ts-ignore
          Axios.get(`${Configuration.api.base}/proximo_anio`, {})
            .then(res => {
              resolve(res.data.data);
            })
            .catch(error => {
              reject(null);
            });
        });
      },
      ifYouHave() {
        return new Promise((resolve, reject) => {
          // @ts-ignore
          Axios.get(`${Configuration.api.base}/disponible_millones`, {})
            .then(res => {
              resolve(res.data.data);
            })
            .catch(error => {
              reject(null);
            });
        });
      },
      debts() {
        return new Promise((resolve, reject) => {
          // @ts-ignore
          Axios.get(`${Configuration.api.base}/deuda_hoy`, {})
            .then(res => {
              resolve(res.data.data);
            })
            .catch(error => {
              reject(null);
            });
        });
      },
      toWho() {
        return new Promise((resolve, reject) => {
          // @ts-ignore
          Axios.get(`${Configuration.api.base}/a_quien_debes`, {})
            .then(res => {
              resolve(res.data.data);
            })
            .catch(error => {
              reject(null);
            });
        });
      },
      houseDebt() {
        return new Promise((resolve, reject) => {
          // @ts-ignore
          Axios.get(`${Configuration.api.base}/credito_hipotecario`, {})
            .then(res => {
              resolve(res.data.data);
            })
            .catch(error => {
              reject(null);
            });
        });
      },
      amountDebt() {
        return new Promise((resolve, reject) => {
          // @ts-ignore
          Axios.get(`${Configuration.api.base}/cuanto_debe_total`, {})
            .then(res => {
              resolve(res.data.data);
            })
            .catch(error => {
              reject(null);
            });
        });
      },
      ifGetCredit() {
        return new Promise((resolve, reject) => {
          // @ts-ignore
          Axios.get(`${Configuration.api.base}/credito_disponible`, {})
            .then(res => {
              resolve(res.data.data);
            })
            .catch(error => {
              reject(null);
            });
        });
      },
      ifGetGifted() {
        return new Promise((resolve, reject) => {
          // @ts-ignore
          Axios.get(`${Configuration.api.base}/te_regalan`, {})
            .then(res => {
              resolve(res.data.data);
            })
            .catch(error => {
              reject(null);
            });
        });
      },
      dreamingCredit() {
        return new Promise((resolve, reject) => {
          // @ts-ignore
          Axios.get(`${Configuration.api.base}/elegir_credito`, {})
            .then(res => {
              resolve(res.data.data);
            })
            .catch(error => {
              reject(null);
            });
        });
      },
      updateData(payload: any) {
        return new Promise((resolve, reject) => {
          // @ts-ignore
          Axios.put(
            // @ts-ignore
            `${Configuration.api.base}/usuario/${store.state.user.id}`,
            payload,
            {
              headers: {
                Authorization: `Bearer ${store.state.token}`
              }
            }
          )
            .then(res => {
              resolve(res.data);
            })
            .catch(error => {
              reject(null);
            });
        });
      },
      business(
        nombre: string,
        rut: string,
        descripcion: string,
        telefono: string,
        direccion: string,
        empresa_target_id: string,
        tiempo_experiencia: string,
        sc_3:number,
        tiempo_local: string,
        sc_4:number,
        a_quien_vendes: string,
        sc_8: string,
        deuda_sin_hipoteca: string,
        deuda_hoy: string,
        alguna_hipoteca: string,
        clavesii?: string,
        razon_social?: string,
        giro?: string,
        web?: string
      ) {
        return new Promise((resolve, reject) => {
          // @ts-ignore
          Axios.post(
            `${Configuration.api.base}/empresa`,
            {
              // @ts-ignore
              user_id: store.state.user.id,
              nombre,
              rut,
              descripcion,
              telefono,
              direccion,
              empresa_target_id,
              tiempo_experiencia,
              sc_3,
              tiempo_local,
              sc_4,
              a_quien_vendes,
              sc_8,
              deuda_sin_hipoteca,
              deuda_hoy,
              alguna_hipoteca,
              clavesii,
              razon_social,
              giro,
              web
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.token}`
              }
            }
          )
            .then((res: any) => {
              resolve(res.data.data);
            })
            .catch((error: any) => {
              reject(error.response);
            });
        });
      },
      request(
        empresa_id: string,
        motivo: string,
        monto: string,
        plazo: number,
        sc_7: number,
        destino?: string,
      ) {
        return new Promise((resolve, reject) => {
          let payload: any = {
            // @ts-ignore
            user_id: store.state.user.id,
            empresa_id,
            motivo,
            monto,
            plazo,
            sc_7,
            destino,
          };
          Axios.post(`${Configuration.api.base}/solicitud/credito`, payload, {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          })
            .then(res => {
              resolve(res.data.data);
            })
            .catch(error => {
              reject(error.response.data);
            });
        });
      },
      owners(owners: any) {
        return new Promise((resolve, reject) => {
          Axios.post(`${Configuration.api.base}/empresa_dueno`, owners, {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          })
            .then(res => {
              resolve(res.data.data);
            })
            .catch(error => {
              reject(error.response.data);
            });
        });
      },
      meetYou(
        first: string,
        second: string,
        third: string,
        eighth: string,
        nineth: string,
        twelfth?: string,
        fourth?: string,
        fifth?: string,
        seventh?: string,
        tenth?: string,
        eleventh?: string
      ) {
        return new Promise((resolve, reject) => {
          // @ts-ignore
          Axios.post(`${Configuration.api.base}/preguntas/accion`, {
            // @ts-ignore
            user_id: store.state.user.id,
            first: first,
            second: second,
            third: third,
            fourth: fourth,
            fifth: fifth,
            seventh: seventh,
            eighth: eighth,
            nineth: nineth,
            tenth: tenth,
            eleventh: eleventh,
            twelfth: twelfth
          })
            .then(res => {
              resolve(res.data.data);
            })
            .catch(error => {
              reject(error);
            });
        });
      },
      carpeta(folder: any, empresa: any) {
        return new Promise((resolve, reject) => {
          let payload: any = {
            carpeta: folder
          }
          // @ts-ignore
          Axios.post(`${Configuration.api.base}/carpeta/${empresa}`, payload, {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          })
            .then((res: any) => {
              resolve(res.data.data);
            })
            .catch((error: any) => {
              reject(error.response);
            });
        });
      },
      submitQuestions(
        first: string,
        second: string,
        third: string,
        fourth: string,
        fifth: string,
        seventh: string,
        eighth: string,
        nineth: string,
        tenth: string,
        eleventh: string
      ) {
        return new Promise((resolve, reject) => {
          // @ts-ignore
          Axios.post(`${Configuration.api.base}/preguntas/accion`, {
            // @ts-ignore
            user_id: store.state.user.id,
            first: first,
            second: second,
            third: third,
            fourth: fourth,
            fifth: fifth,
            seventh: seventh,
            eighth: eighth,
            nineth: nineth,
            tenth: tenth,
            eleventh: eleventh
          })
            .then(res => {
              resolve(res.data.data);
            })
            .catch(error => {
              reject(error);
            });
        });
      }
    }
  },
  actividad:{
    organiza:{
      guardarlistado(answers:any){
        return new Promise((resolve, reject)=>{
          Axios.post(`${Configuration.api.base}/cta/cta11`, answers, {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }).then(res=>{
            resolve(res)
          }).catch(error => {reject(error)})
        })
      },
      listadosituacion(answers:any){
        return new Promise((resolve,reject)=>{
          Axios.post(`${Configuration.api.base}/cta/cta12`, answers, {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }).then(res=>{
            resolve(res)
          }).catch(error => {reject(error)})
        })
      },guardarfirma(answers:any){
        return new Promise((resolve,reject)=>{
          Axios.post(`${Configuration.api.base}/cta/cta13`, answers, {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }).then(res=>{
            resolve(res)
          }).catch(error => {reject(error)})
        })
      },guardarfinanzas(answers:any){
        return new Promise((resolve,reject)=>{
          Axios.post(`${Configuration.api.base}/cta/cta14`, answers, {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }).then(res=>{
            resolve(res)
          }).catch(error => {reject(error)})
        })
      }
    },costos:{
      comprension(answers:any){
        return new Promise((resolve,reject)=>{
          Axios.post(`${Configuration.api.base}/cta/cta21`, answers, {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }).then(res=>{
            resolve(res)
          }).catch(error => reject(error))
        })
      },dejastedevender(answers:any){
        return new Promise((resolve,reject)=>{
          Axios.post(`${Configuration.api.base}/cta/cta23`, answers, {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }).then(res=>{
            resolve(res)
          }).catch(error => reject(error))
        })
      },subirimg(data:any){
        return new Promise((resolve,reject)=>{
          Axios.post(`${Configuration.api.base}/cta/cta22`, 
             { 
               data
             },{
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }).then(res=>{
            resolve(res)
          }).catch(error => reject(error))
        })
      },prodestrella(answers:any){
        return new Promise((resolve,reject)=>{
          Axios.post(`${Configuration.api.base}/cta/cta24`, answers, {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }).then(res=>{
            resolve(res)
          }).catch(error => reject(error))
        })
      }
    },metascortoplazo:{
      objetivosmes(answers:any){
        return new Promise((resolve,reject)=>{
          Axios.post(`${Configuration.api.base}/cta/cta31`, answers, {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }).then(res=>{
            resolve(res)
          }).catch(error => reject(error))
        })
      },objetivosanio(answers:any){
        return new Promise((resolve,reject)=>{
          Axios.post(`${Configuration.api.base}/cta/cta32`, answers, {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }).then(res=>{
            resolve(res)
          }).catch(error => reject(error))
        })
      },objetivobussiness(answers:any){
        return new Promise((resolve,reject)=>{
          Axios.post(`${Configuration.api.base}/cta/cta34`, answers, {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }).then(res=>{
            resolve(res)
          }).catch(error => reject(error))
        })
      }
    },gestionadeuda:{
      calcular(answers:any){
        return new Promise((resolve,reject)=>{
          Axios.post(`${Configuration.api.base}/cta/cta41`, answers, {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }).then(res=>{
            resolve(res)
          }).catch(error => reject(error))
        })
      },capacidadpago(answers:any){
        return new Promise((resolve,reject)=>{
          Axios.post(`${Configuration.api.base}/cta/cta42`, answers, {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }).then(res=>{
            resolve(res)
          }).catch(error => reject(error))
        })
      },vendescredito(answers:any){
        return new Promise((resolve,reject)=>{
          Axios.post(`${Configuration.api.base}/cta/cta43`, answers, {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }).then(res=>{
            resolve(res)
          }).catch(error => reject(error))
        })
      }
    },infoactividades:{
      actividadesrealizadas(id:any){
        return new Promise((resolve, reject) => {
          // @ts-ignore
          Axios.get(`${Configuration.api.base}/cta/advance/${id}`,{ headers: {
            Authorization: `Bearer ${store.state.token}`
          }
          })
            .then(res => {
              resolve(res);
            })
            .catch(error => {
              reject(null);
            });
        });
      }
    }
  },
  async queHacemos(){
    const info = await Axios.get(`${Configuration.api.base}/listado_que_hacemos`);

    return info.data;
  },
  async listar_clasificaciones(){
    const listar_clasificaciones =await Axios.get(`${Configuration.api.base}/listar_clasificaciones`,
    { 
      headers: { 
        Authorization: `Bearer ${store.state.token}`
      } 
    })
    return listar_clasificaciones.data
  },
  factoring: {
    sii(businesSelected_id:any,password:any,c_password:any){
      return new Promise((resolve,reject)=>{
        Axios.post(`${Configuration.api.base}/sii-configurations/${businesSelected_id}/password`,{
          password: password,
          password_confirmation: c_password,
        },{
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        })
        .then((res)=>{
          resolve(res)
        })
        .catch((err)=>{
          reject(err)
        })
      })
    },
    certificado_sii(businesSelected_id:any,archivo:any,c_password:any){
      return new Promise((resolve,reject)=>{
        let formData = new FormData();
        archivo.forEach((f:any) => {
          formData.append('original', f);
        });
        formData.append('password', c_password);
        Axios.post(`${Configuration.api.base}/empresas/${businesSelected_id}/certificadosDigitales/upload`,
         formData
        ,
          { 
            headers: { 
              Authorization: `Bearer ${store.state.token}`,
              'Content-Type': 'multipart/form-data'
            } 
          }
        )
        .then((res)=>{
          resolve(res)
        })
        .catch((err)=>{
          reject(err)
        })
      })
    },
    factureUploads(businesSelected_id:any,archivo:any){
      return new Promise((resolve,reject)=>{
        let formData = new FormData();
        archivo.forEach((el: any)=>{
          formData.append('xmls[]',el)
        })
        formData.append('empresa_id', businesSelected_id);
        Axios.post(`${Configuration.api.base}/cargar_facturas_xml`,
        formData
        ,
          { 
            headers: { 
              Authorization: `Bearer ${store.state.token}`,
              'Content-Type': 'multipart/form-data'
  
            } 
          }
        )
        .then((res)=>{
          resolve(res)
        })
        .catch((err)=>{
          reject(err.response)
        })
      })
    },
    cederFacture(businesSelected_id:any,id_factura:any){
      return new Promise((resolve,reject)=>{
        Axios.post(`${Configuration.api.base}/empresas/${businesSelected_id}/facturasSubidas/${id_factura}/ceder`, {},
        { 
          headers: { 
            Authorization: `Bearer ${store.state.token}`
          } 
        }
        ).then((res)=>{
          resolve(res)
        }).catch((err)=>{
          reject(err)
        })
      })
    },
    async cederFacturasMasivo(businesSelected_id:any,facturas:any){
      const ceder = await Axios.post(`${Configuration.api.base}/ceder_masivo/${businesSelected_id}`, 
        {facturas},
        { 
          headers: { 
            Authorization: `Bearer ${store.state.token}`
          } 
        }
      );
      return ceder.data;
    },
    async listarFacturasParaCeder(businesSelected_id:any){
      const listado_faturas = await Axios.get(`${Configuration.api.base}/empresas/${businesSelected_id}/facturasSubidas/ceder`, 
        { 
          headers: { 
            Authorization: `Bearer ${store.state.token}`
          } 
        }
      );
      return listado_faturas.data;
    },
    listarFacturasCargadas(businesSelected_id:any){
      return new Promise((resolve,reject)=>{
        Axios.get(`${Configuration.api.base}/empresas/${businesSelected_id}/facturasSubidas`,{
           headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }).then((res)=>{
          resolve(res)
        })
        .catch((err)=>{
          reject(err)
        })
      })
    },
    listarFacturasCargadasFiltradas(businesSelected_id:any){
      return new Promise((resolve,reject)=>{
        Axios.get(`${Configuration.api.base}/empresas/${businesSelected_id}/facturasSubidas/filtradas`,{
           headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }).then((res)=>{
          resolve(res)
        })
        .catch((err)=>{
          reject(err)
        })
      })
    },
    cargarFactura(businesSelected_id:any,formData:any){
      return new Promise((resolve,reject)=>{
        Axios.post(`${Configuration.api.base}/empresas/${businesSelected_id}/facturasSubidas/upload`, formData,
        { headers: {
          Authorization: `Bearer ${store.state.token}`,
          "X-Requested-With": "XMLHttpRequest",
        }, }
        ).then((res)=>{
          resolve(res)
        }).catch((err)=>{
          reject(err)
        })
      })
    },
    carga_masiva_facturas_sin_proyecto(formData:any){
      return new Promise((resolve,reject)=>{
        Axios.post(`${Configuration.api.base}/carga_masiva_facturas_sin_proyecto`, formData,
        { headers: {
          Authorization: `Bearer ${store.state.token}`,
        }, }
        ).then((res)=>{
          resolve(res)
        }).catch((err)=>{
          reject(err)
        })
      })
    },
    
    sincronizar_emitidos(rut:any){
     return new Promise((resolve,reject)=>{
      Axios.get(`${Configuration.api.base}/sii/${rut}/sincronizar_emitidos`,{
        headers: {
         Authorization: `Bearer ${store.state.token}`
       }
      }).then((res)=>{
        resolve(res)
      })
      .catch((err)=>{
        reject(err)
      })
     })
    },
    async lista_parametros_cotizacion(){
      const api_key = 'key_onboarding_prod_fnPqT5xQEi5Vcb9wKwbCf65c3BjVGyBB';
      const formData = new FormData();
      formData.append('api_key', api_key);
      const parametros = await Axios.post(`${Configuration.api.base}/lista_parametros_cotizacion`, formData,{
          headers: {
           Authorization: `Bearer ${store.state.token}`
         }
        })
      return parametros.data;
    },
    async date_parametros_onboarding(){
      const dates = await Axios.get(`${Configuration.api.base}/cotizacion/params/onboarding`,{
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
      return dates.data;

    },
    crear_cotizacion(cotizacion:any){
      return new Promise((resolve,reject)=>{
        Axios.post(`${Configuration.api.base}/crear_cotizacion`, 
          cotizacion,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
        ).then((res)=>{
          resolve(res)
        }).catch((err)=>{
          reject(err)
        })
      })
    },
    async mis_cotizaciones(empresa_id:any){
      const mis_cotizaciones = await Axios.get(`${Configuration.api.base}/mis_cotizaciones/${empresa_id}`,{
        headers: {
        Authorization: `Bearer ${store.state.token}`
      }
      })
      return mis_cotizaciones.data
    },
    async get_notificaciones(){
      const info_user_notificacion = await Axios.get(`${Configuration.api.base}/info_user_notificacion`,
      {   
        headers :{
          Authorization: `Bearer ${store.state.token}`
        }
      })
      return info_user_notificacion;
    },
    async get_cuentas(id_empresa:any){
      const listar_mis_cuentas = await Axios.get(`${Configuration.api.base}/listar_mis_cuentas`, 
      {
        params:{
          empresa_id:id_empresa
        },
        headers :{
          Authorization: `Bearer ${store.state.token}`
        }
      })
      return listar_mis_cuentas.data;
    },
    async get_cuenta_sii(dni:any){
       const cuenta_sii = await Axios.get(`${Configuration.api.base}/obtener_cuenta_sii`,
      { params:{
          rut:dni
        },
        headers :{
          Authorization: `Bearer ${store.state.token}`
        }
      })
      return cuenta_sii;
    },
    async get_cuenta_previred(dni:any){
      const cuenta_previred = await Axios.get(`${Configuration.api.base}/obtener_cuenta_previred`,
     {  
       params:{
        rut:dni
      },
       headers :{
         Authorization: `Bearer ${store.state.token}`
       }
     })
     return cuenta_previred;
    },
    async get_cuenta_tgr(dni:any){
      const obtener_cuenta_tgr = await Axios.get(`${Configuration.api.base}/obtener_cuenta_tgr`,
      { 
        params:{
        rut:dni
      },
        headers :{
          Authorization: `Bearer ${store.state.token}`
        }
      })
      return obtener_cuenta_tgr;
    },
    async guardar_cuenta_tgr(dni:any,clave_tgr:any){
      const guardar_cuenta_tgr = await Axios.post(`${Configuration.api.base}/agregar_cuenta_tgr`,
      {
        "rut":dni,
        "clave": clave_tgr
      },
      {
        headers: {
        Authorization: `Bearer ${store.state.token}`
      }
      })
      return guardar_cuenta_tgr.data
    },
    async guardar_cuenta_previred(dni:any,clave_tgr:any){
      const agregar_cuenta_previred = await Axios.post(`${Configuration.api.base}/agregar_cuenta_previred`,
      {
        "rut":dni,
        "clave": clave_tgr
      },
      {
        headers: {
        Authorization: `Bearer ${store.state.token}`
      }
      })
      return agregar_cuenta_previred.data
    },
    async guardar_actualizar_notificacion(mail:any,whatsapp:any,news:any){
      const actualizar_notificacion = await Axios.post(`${Configuration.api.base}/actualizar_notificacion`,
      {
        "whatsapp": whatsapp,
        "news": news,
        "mail": mail
      },
      {
        headers: {
        Authorization: `Bearer ${store.state.token}`
      }
      })
      return actualizar_notificacion.data
    },
    async guardar_cuenta_banco(cuenta:any){
      const cuenta_banco = await Axios.post(`${Configuration.api.base}/crear_cuenta_bancaria_usuario`,
      {
        "banco_id": cuenta.banco_id,
        "cuenta_tipo_id":cuenta.tipo_id,
        "numero": cuenta.numero,
        "cci": cuenta.cci,
        "empresa_id": cuenta.empresa_id,
        "moneda_id": cuenta.moneda_id,
      },
      {
        headers: {
        Authorization: `Bearer ${store.state.token}`
      }
      })
      return cuenta_banco.data
    }, 
    async actualizar_cuenta_bancaria(cuenta:any){
        const actualizar_cuenta_bancaria_usuario = await Axios.put(`${Configuration.api.base}/actualizar_cuenta_bancaria_usuario`,
        {
          "cuenta_id": cuenta.cuenta_id,
          "banco_id": cuenta.banco_id,
          "cuenta_tipo_id": cuenta.tipo_id,
          "numero": cuenta.numero,
          "empresa_id": cuenta.empresa_id,
          "moneda_id": cuenta.moneda_id,
          "cci": cuenta.cci,
          "alias": cuenta.alias
        },
        {
          headers: {
          Authorization: `Bearer ${store.state.token}`
        }
        })
        return actualizar_cuenta_bancaria_usuario.data
    },
    async validar_cuenta_bancaria(cuenta_id:any){
        const actualizar_cuenta_bancaria_usuario = await Axios.put(`${Configuration.api.base}/validarCuenta`,
        {
          "cuenta_id": cuenta_id,
        },
        {
          headers: {
          Authorization: `Bearer ${store.state.token}`
        }
        })
        return actualizar_cuenta_bancaria_usuario.data
    },
    async actualizarCuentaPredeterminada(id_cuenta:number){

      const cuenta_predeterminada = await Axios.post(`${Configuration.api.base}/actualizar_cuenta_predeterminada `, 
      {
        "cuenta_id": id_cuenta,
        "sistema_id": 3,
      },
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        }
      });

      return cuenta_predeterminada.data;
    },
    async factura_subir_pdf(empresa_id:any,id_factura:any,file_pdf:any){
      let formData = new FormData();
      formData.append('archivo',file_pdf)
      formData.append('id',id_factura)
      const pdf = await Axios.post(`${Configuration.api.base}/empresas/${empresa_id}/facturasSubidas/subir_respaldo_factura`,
      formData,
      { 
        headers: { 
          Authorization: `Bearer ${store.state.token}`,
          'Content-Type': 'multipart/form-data'
        } 
      })
      return pdf.data
    },
    async actualizar_estados_factura(empresa_id:any,id_factura:any,id_estado:any){
      const actualizar_estados_factura = await Axios.post(`${Configuration.api.base}/empresas/${empresa_id}/facturasSubidas/actualizar_estado_factura`,
      {
        id:id_factura,
        id_estado
      },
      { 
        headers: { 
          Authorization: `Bearer ${store.state.token}`
        } 
      })
      return actualizar_estados_factura.data
    },
    async actualizarFechaVencimiento(facturas_subidas:any){
      const actualizar_fecha_vencimiento = await Axios.post(`${Configuration.api.base}/actualizar_fecha_vencimiento`,
      {
        facturas_subidas
      },
      { 
        headers: { 
          Authorization: `Bearer ${store.state.token}`
        } 
      })
      return actualizar_fecha_vencimiento.data
    },
    async ultimoPasoEmpresa(empresa_id:any,origen:any){
      const info = new FormData();
      info.append('empresa_id', empresa_id);
      info.append('origen', origen);

      const {data} = await Axios.post(`${Configuration.api.base}/track/onboarding/ultimo_paso`,info,
      {
        headers: { 
          Authorization: `Bearer ${store.state.token}`
        } 
      })
      return data;
    },
    async tranckingOnboarding(origen:any,empresa_id:any,ip_address:any,variable:any,info?:any,facturas?:any){
      const tranckingOnboarding = await Axios.post(`${Configuration.api.base}/track/onboarding/crear_paso`,
      {
        origen,
        empresa_id,
        ip_address,
        variable,
        info,
        facturas
      },
      { 
        headers: { 
          Authorization: `Bearer ${store.state.token}`
        } 
      })
      return tranckingOnboarding.data
    },
    async listar_facturas_onboarding(empresa_id:any){
      const {data} = await Axios.get(`${Configuration.api.base}/listar_facturas_onboarding/${empresa_id}`,
      {
        headers: { 
          Authorization: `Bearer ${store.state.token}`
        } 
      })
      return data;
    },
    async obtenerCertificado(empresa_id:any){
      const data = await Axios.get(`${Configuration.api.base}/empresas/${empresa_id}/certificadoDigital`,
      {
        headers: { Authorization : `Bearer ${store.state.token}`}
      })
      return data

    }, 
    async descargarPdf(empresa_id:any,documento_id:any) {
      const data = await Axios.get(`${Configuration.api.base}/descargar_pdf/${empresa_id}/${documento_id}`,
      {
        headers: { Authorization : `Bearer ${store.state.token}`},
        responseType: 'blob'
      })
      return data
    },
    async sii_vinculado(empresa_id:any){
      const data = await Axios.get(`${Configuration.api.base}/empresas/${empresa_id}/sii`,
      {
        headers: { Authorization : `Bearer ${store.state.token}`},
      })
      return data.data
    },
    async simularCotizacion(simulacion:any){
      const simular_cotizacion =await Axios.post(`${Configuration.api.base}/simular_cotizacion_onboarding`,
        simulacion,
      { 
        headers: { 
          Authorization: `Bearer ${store.state.token}`
        } 
      })
      return simular_cotizacion.data
    },
    async subir_respaldo_cotizacion(formData: any){
      const respaldo = await Axios.post(`${Configuration.api.base}/subir_respaldo_cotizacion`, 
       formData,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`,
            'Content-Type': 'multipart/form-data'
          }
        })
      return respaldo.data
    },
    async solicitar_pago_excedente(formData:any){
      const pago_excendente = await Axios.post(`${Configuration.api.base}/operacion/factoring/excedentes`,
      formData
      ,
       {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'Content-Type': 'multipart/form-data'
        }
       })
       return pago_excendente.data
    },
    async sumaFacturas(empresa_id:any){
      const sumaFacturas = await Axios.get(`${Configuration.api.base}/suma_facturas_dte/${empresa_id}`,
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        }
      })
      return sumaFacturas.data
    },
    async listarFacturasDTE(empresa_id:any){
      const data = new FormData();
      data.append('empresa_id', empresa_id.toString());

      const facturasDte = await Axios.post(`${Configuration.api.base}/listar_facturas_dte`, data, 
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        }
      })
      return facturasDte.data
    },

    async listarFacturasFactoring(empresa_id:any){
      const data = new FormData();
      data.append('empresa_id', empresa_id.toString());

      const facturas = await Axios.post(`${Configuration.api.base}/listar_facturas_factoring`, data, 
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        }
      })
      return facturas.data
    },

    async datosResumenEmpresa(empresa_id:any){
      var formData = new FormData()
      formData.append("empresa_id",empresa_id);
      const data_empresa = await Axios.post(`${Configuration.api.base}/data_empresa`,
      formData
      ,
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        }
      })
      return data_empresa.data;
    },
    async listarFacturasConfirming(empresa_id:number){
      const data = new FormData();
      data.append('empresa_id', empresa_id.toString());

      const facturasConfirming = await Axios.post(`${Configuration.api.base}/listar_facturas_dte_confirming`,data,
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        }
      })
      return facturasConfirming.data;

    },
    async datosPoderJudicial(rut_empresa:any){
      const data_poderJudicial = await Axios.get(`${Configuration.api.base}/data_pjud/${rut_empresa}`,
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        }
      })
      return data_poderJudicial.data;
    },
    async datosConvenios(rut_empresa:any){
      const datosConvenios = await Axios.get(`${Configuration.api.base}/data_convenios/${rut_empresa}`,
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        }
      })
      return datosConvenios.data;
    },
    async datosGraficos(rut_empresa:any){
      const datosGraficos = await Axios.get(`${Configuration.api.base}/data_grafo/${rut_empresa}`,
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        }
      })
      return datosGraficos.data;
    },
    async buscarEmpresaRUC(ruc:number){
      const empresa = await Axios.get(`${Configuration.api.base}/empresa/buscar_por_ruc/${ruc}`,
      {
        headers:{
          Authorization: `Bearer ${store.state.token}`,
        }
      })
      return empresa.data;
    },
    async datosEmpresa(id:number){
      const empresa = await Axios.get(`${Configuration.api.base}/empresa/${id}`,
      {
        headers:{
          Authorization: `Bearer ${store.state.token}`,
        }
      })
      return empresa;
    },
    async actualizardatosEmpresa(datosempresa:any){
      const empresa = await Axios.put(`${Configuration.api.base}/empresa/actualizar/${datosempresa.id}`,{
           // @ts-ignore
          user_id: store.state.user.id,
          nombre: datosempresa.nombre,
          rut: datosempresa.rut,
          descripcion: datosempresa.descripcion,
          telefono: datosempresa.telefono,
          direccion: datosempresa.direccion
      },
      {
        headers:{
          Authorization: `Bearer ${store.state.token}`,
        }
      })
      return empresa.data;
    },
    async validarEmpresaUsuario(empresa_id:string){
      const data = new FormData();
      data.append('empresa_id', empresa_id);

      const response = await Axios.post(`${Configuration.api.base}/usuario/validar_usuario`, data,{
        headers:{
          Authorization: `Bearer ${store.state.token}`,
        }
      });

      return response.data;
    },

    async loginSunat(empresa_ruc:string, user_sol:string, clave_sol:string, token:string){
      const data = new FormData();
      data.append('ruc', empresa_ruc);
      data.append('user_sol', user_sol);
      data.append('clave_sol', clave_sol);
      data.append('token_sistema', token);

      const response = await Axios.post(`${Configuration.api.base}/empresa/login_sunat_empresa`, data,{
        headers:{
          Authorization: `Bearer ${store.state.token}`,
        }
      });

      return response;
    },
    async estadoEmpresaSunat(empresa_id:string, token:string){
      const data = new FormData();
      data.append('empresa_id', empresa_id);
      data.append('token_sistema', token);

      const response = await Axios.post(`${Configuration.api.base}/empresa/estado_empresa_sunat`, data,{
        headers:{
          Authorization: `Bearer ${store.state.token}`,
        }
      });

      return response.data;
    },
    async sumaFacturasDTE(empresa_id:string){
      const data = new FormData();
      data.append('empresa_id', empresa_id);

      const response = await Axios.post(`${Configuration.api.base}/suma_facturas_dte`, data,{
        headers:{
          Authorization: `Bearer ${store.state.token}`,
        }
      });

      return response.data;
    }
	},
  async getMonedas(){
    const monedas = await Axios.get(`${Configuration.api.base}/monedas`,
    {
      headers:{
        Authorization: `Bearer ${store.state.token}`,
      }
    })
    return monedas.data.data;
  },

  getPasswordRules(){
    return Axios.get(`${Configuration.api.base}/password_rules`, {
      headers: {
        Authorization: `Bearer ${store.state.token}`,
      }
    });
  },
  /* “ruc”: 1016693060511,
    “nombre_empresa”: “Prueba”,
    “nombre_contacto”: “Prueba Nombre”,
    “numero_contacto”: 123456789,
    “email_contacto”: “f.ramos@redcapital.cl” */
  contactoTarjetaVisa(
    ruc: number,
    nombre_empresa: string,
    nombre_contacto: string,
    numero_contacto: number,
    email_contacto: string,
    via?:string 
  ) {
    return new Promise((resolve, reject) => {
      Axios.post(
        `${Configuration.api.base}/contacto_tarjeta_visa`,
        {
          ruc,
          nombre_empresa,
          nombre_contacto,
          numero_contacto,
          email_contacto, 
          via 
        },
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          resolve(error.response.data);
        });
    });
  },
  async getOperacionesResumeEmpresa(empresa_id:number){
      const response = await Axios.get(`${Configuration.api.base}/resumen/${empresa_id}`,{
        headers:{
          Authorization: `Bearer ${store.state.token}`,
        }
      });
      return response;
  },
  async getOperacionesEmpresa(empresa_id:number){

      const response = await Axios.get(`${Configuration.api.base}/operaciones/${empresa_id}` ,{
        headers:{
          Authorization: `Bearer ${store.state.token}`,
        }
      });
      return response;
  },
  async getEstadosOnboarding(){

      const response = await Axios.get(`${Configuration.api.base}/onboarding_estados` ,{
        headers:{
          Authorization: `Bearer ${store.state.token}`,
        }
      });
      return response;
  },
  
  getEmpresasSUNAT(){
      const formData = new FormData();
      //@ts-ignore
      formData.append('user_id', store.state.user.id);
      const response = Axios.post(`${Configuration.api.base}/empresa/get_empresas_sunat`,formData,{
        headers:{
          Authorization: `Bearer ${store.state.token}`,
        }
      });
      return response;
  },
  async getFacturasEmitidasSUNAT(ruc_empresa:string){

    const formData = new FormData();
    formData.append('tipo_consulta', 'FE EMITIDAS');
    formData.append('ruc', ruc_empresa);
    
    const response = await Axios.post(`${Configuration.api.base}/sync_facturas`,formData,{
      headers:{
        Authorization: `Bearer ${store.state.token}`,
      }
    });

    return response;
  },

  async loginCavali(empresa_ruc:string, user_sol:string, clave_sol:string, token:string){
    const data = new FormData();
    data.append('ruc', empresa_ruc);
    data.append('user_cavali', user_sol);
    data.append('clave_cavali', clave_sol);
    data.append('token_sistema', token);

    const response = await Axios.post(`${Configuration.api.base}/cavali/login`, data,{
      headers:{
        Authorization: `Bearer ${store.state.token}`,
      }
    });
    return response.data;
  },

  async estadoEmpresaCavali(empresa_ruc:string, token:string){
    const data = new FormData();
    data.append('ruc', empresa_ruc);
    data.append('token_sistema', token);

    const response = await Axios.post(`${Configuration.api.base}/cavali/estado_sesion`, data,{
      headers:{
        Authorization: `Bearer ${store.state.token}`,
      }
    });

    return response.data;
  },
  async obtenerClasificacionesCredito(){
    const response =  await Axios.get(`${Configuration.api.base}/opcionesCredito`);

    return response.data;
  },
  async obtenerPlazoFactura(factura_id:number) {
    try {
      const formData = new FormData();
      formData.append('factura_subida_id', factura_id.toString());
  
      const response = await Axios.post(`${Configuration.api.base}/obtener_plazo_facturas`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        }
      });
  
      return response.data;  
    } catch (error) {
      throw error;  
    }
  },
  getEmpresas(){
    const formData = new FormData();
    //@ts-ignore
    formData.append('user_id', store.state.user.id);
    const response = Axios.post(`${Configuration.api.base}/empresa/get_empresas_usuario`,formData,{
      headers:{
        Authorization: `Bearer ${store.state.token}`,
      }
    });
    return response;
  },

  getRespaldosEmpresa(empresa_id:number) {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Axios.get(
        // @ts-ignore
        `${Configuration.api.base}/respaldos/empresa/${empresa_id}`,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data.data);
        })
        .catch(error => {
          reject(null);
        });
    });
  },

  async guardarRespaldo(respaldo: any) {
    const formData = new FormData();
  
    formData.append('tabla_slug', respaldo.tabla_slug);
    formData.append('idx', respaldo.idx);
    formData.append('tipo_respaldo', respaldo.tipo_respaldo);
    formData.append('documento', respaldo.documento);
  
    const response = await Axios.post(`${Configuration.api.base}/respaldos/registrar`, formData, {
      headers: {
        Authorization: `Bearer ${store.state.token}`,
        'Content-Type': 'multipart/form-data'
      }
    });
  
    return response.data;
  },

  async getTipoRespaldos(tabla_slug: string) {
    const response = await Axios.post(`${Configuration.api.base}/respaldos/tipo_respaldos`,
    {
      "tabla_slug": tabla_slug,
    },
    {
      headers: {
      Authorization: `Bearer ${store.state.token}`
    }
    })
    return response.data.data
  },

  async eliminarRespaldo(respaldo_id: string){
    const response = await Axios.post(`${Configuration.api.base}/respaldos/eliminar`,
    {
      "respaldo_id": respaldo_id
    },
    {
      headers: {
      Authorization: `Bearer ${store.state.token}`
    }
    })
    return response.data
  },

  async descargarRespaldo(respaldo_id: string){
    const response = await Axios.get(`${Configuration.api.base}/respaldos/descargar/${respaldo_id}`,
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      }
    );
    return response.data
  },
};
