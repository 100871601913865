<template>
	<div>
		<v-data-table
			v-resize="onResize"
			:class="{mobile: isMobile}"  
			:no-data-text="dataTableInfo.msjEmpty" 
			:rows-per-page-text="dataTableInfo.rowsPerPage.txt" 
			:rows-per-page-items="dataTableInfo.rowsPerPage.itm" 
			:headers="dataTableInfo.headers" 
			:items="itemTable" style="border-radius: 0px 0px 25px 25px;" 
			:pagination.sync="dataTableInfo.pagination" 
			:hide-headers="isMobile"
			:search="buscar"			
			no-results-text="No se encontraron registros">
			
			<template v-slot:items="props">
				<tr v-if="!isMobile">
					<td v-for="(data, i) in props.item.datosEnTabla" :key="`${i}`" style="font-size:0.7rem">
						<div v-if="data.component" v-bind="data.props">
							<component :is="data.component.name" v-bind="data.component.props"></component>
							<!-- <span @click="getInvestById2Child(props.item.id)">Click2</span> -->
						</div>
						<div v-else-if="data.chip" v-bind="data.props">
							<v-btn round v-if="data.chip.selected" :class="data.chip.class" style="text-transform: capitalize;height: 1.8rem;">{{data.chip.text}}</v-btn>
							<!-- <v-chip v-if="data.chip.selected" :class="data.chip.class">{{data.chip.text}}</v-chip> -->
							<v-btn round v-else @click="handleFuncion(data.chip.funcion,data.chip.data)" :class="data.chip.class" style="text-transform: capitalize;height: 1.8rem;">{{data.chip.text}}</v-btn>
						</div>
						<div v-else-if="data.href" v-bind="data.props">
							<v-btn class="rc-btnTable" :href="data.href.to" target="_blank" icon>
								<span :class="{'material-icons' : data.href.notOutline, 'material-icons-outlined' : !data.href.notOutline}" style="height:26px;width:26px">
									{{data.href.icoFont}}
								</span>
							</v-btn>
						</div>
						<div v-else-if="data.button" v-bind="data.props">
							<v-btn class="rc-btnTable" @click="handleFuncion(data.button.funcion,data.button.data)" icon :disabled="data.button.disable ? true : false ">
								<img v-if="data.button.ico" :src="data.button.ico" alt="card" style="width: 80%;"/>
								<span v-else :class="{'material-icons' : data.button.notOutline, 'material-icons-outlined' : !data.button.notOutline}" style="height:26px;width:26px">
									{{data.button.icoFont}}
								</span>
							</v-btn>
						</div>
						<div v-else-if="data.buttons" v-bind="data.props">
							<div v-for="(button, j) in data.buttons" :key="`${j}`" style="float:left">
								<div v-if="button.tooltip">
									<v-tooltip bottom>
									<template v-slot:activator="{ on }">
										<v-btn v-on="on" class="rc-btnTable" @click="handleFuncion(button.funcion, button.data)" v-bind="button.props" icon :disabled="button.disable ? true : false ">
											<span class="material-icons-outlined" style="height:26px;width:26px">
												{{button.icoFont}}
											</span>
										</v-btn>
									</template>
									<span>{{button.tooltip}}</span>
									</v-tooltip>
								</div>
								<v-btn v-else class="rc-btnTable" @click="handleFuncion(button.funcion, button.data)" v-bind="button.props" icon :disabled="button.disable ? true : false ">
									<span class="material-icons-outlined" style="height:26px;width:26px">
										{{button.icoFont}}
									</span>
								</v-btn>
								
							</div>
						</div>				
						<div v-else-if="data.select">
							<div v-for="(selectores, j) in data.select" :key="`${j}`">
								<v-flex xs12 justify-center>
									<v-layout row justify-center>
										<v-flex xs6>
											<v-select class="rc-btnTable"
											:items="selectores.data"
											:label="selectores.label"
											v-model="selector"
											:disabled="selectores.estado"
											item-text="name"
											item-value="id"		
											@change="handleFuncion(selectores.funcion, selector)"
											>
										</v-select>
										</v-flex>										
									</v-layout>
								</v-flex>
								
							</div>
						</div>
						
						<div v-else v-bind="data.props">
							{{ data.value }}
						</div>
					</td>
				</tr>
				<tr v-else style="display: block; height: max-content !important">
					<td>
						<ul class="flex-content" style="padding: 10px"> 
							<li class="flex-item" style="text-align: left" :data-label="data.label" v-for="(data, i) in props.item.datosEnTabla" :key="`${i}`" >
								<div v-if="data.component">
									<component :is="data.component.name" v-bind="data.component.props"></component>
									<!-- <span @click="getInvestById2Child(props.item.id)">Click2</span> -->
								</div>
								<div v-else-if="data.chip" v-bind="data.props">
									<v-btn round v-if="data.chip.selected" :class="data.chip.class" style="text-transform: capitalize;height: 1.8rem;">{{data.chip.text}}</v-btn>
									<!-- <v-chip v-if="data.chip.selected" :class="data.chip.class">{{data.chip.text}}</v-chip> -->
									<v-btn round v-else @click="handleFuncion(data.chip.funcion,data.chip.data)" :class="data.chip.class" style="text-transform: capitalize;height: 1.8rem;">{{data.chip.text}}</v-btn>
								</div>
								<div v-else-if="data.href" v-bind="data.props">
									<v-btn class="rc-btnTable" :href="data.href.to" target="_blank" icon>
										<span :class="{'material-icons' : data.href.notOutline, 'material-icons-outlined' : !data.href.notOutline}" style="height:26px;width:26px">
											{{data.href.icoFont}}
										</span>
									</v-btn>
								</div>
								<div v-else-if="data.button">
									<v-btn class="rc-btnTable" @click="handleFuncion(data.button.funcion,data.button.data)" icon
									style="top: -13px !important" :disabled="data.button.disable ? true : false ">
										<img v-if="data.button.ico" :src="data.button.ico" alt="card" style="width: 80%;"/>
										<span v-else :class="{'material-icons' : data.button.notOutline, 'material-icons-outlined' : !data.button.notOutline}" style="height:26px;width:26px">
											{{data.button.icoFont}}
										</span>
									</v-btn>
								</div>
								<div v-else-if="data.buttons" v-bind="data.props">
									<div v-for="(button, j) in data.buttons" :key="`${j}`" style="float:left;">
										<v-btn class="rc-btnTable" @click="handleFuncion(button.funcion, button.data)" v-bind="button.props" icon :disabled="button.disable ? true : false ">
											<span class="material-icons-outlined" style="height:26px;width:26px">
												{{button.icoFont}}
											</span>
										</v-btn>
									</div>
								</div>
								<div v-else-if="data.select">
									<div v-for="(selectores, j) in data.select" :key="`${j}`" style="float:left;">
										<v-select class="rc-btnTable"
										:items="items"
										label="Selector"
										:id="items"
										>
										</v-select>
									</div>
								</div>
								<div v-else style="font-weight: 600">
									{{ data.value }}
								</div>
							</li>
						</ul>
					</td>
				</tr>
			</template>
			<template v-slot:pageText="props">
				{{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
			</template>
		</v-data-table>
	</div>
</template>

<script>
	import { Component, Vue } from "vue-property-decorator";
	import ProjectStatus from "@/components/dashboard/ProjectStatus.vue";

	@Component({
		props: {
			// datos
			dataTableInfo: Object,
			itemTable: Array,
			buscar: String
		},
		computed: {
		},
		data() { // Data Menu
			return {
				selector: null,
				isMobile: false,
				estadoChip: false
			}
		},
		// beforeMount() {}, // Antes de Montar el componente
		// beforeUpdate(){}, // Antes de Actualizar el componente
		mounted(){
			 // @ts-ignore
			this.onResize();
			// @ts-ignore
			window.addEventListener("resize", this.onResize);
		},
		methods:{
			// tabla responsive
			onResize() {
				if (window.innerWidth < 961)
					this.$data.isMobile = true;
				else
					this.$data.isMobile = false;
			},
			handleFuncion(nameFuncion, data){
				this[nameFuncion](data);
			},
			// Resumen Inversiones
			getInvestById2Child(data){
				//console.log(this.$emit('probandoFuncion'));
				this.$emit('getInvestById2Parent', data);
			},
			showCommentsChild(data){
				this.$emit('showCommentsParent', data);
			},
			showDocumentsChild(data){
				this.$emit('showDocumentsParent', data);
			},
			// Transferencias Pendientes
			payChild(data){
				this.$emit('payParent', data);
			},
			showComentariosChild(data){
				this.$emit('showComentariosParent', data);
			},
			showDetallesChild(data){
				this.$emit('showDetallesParent', data);
			},
			// Funciones MisPagos
			misPagos_showCommentsChild(data){
				this.$emit('misPagos_showCommentsParent', data);
			},
			misPagos_showFeesChild(data){
				this.$emit('misPagos_showFeesParent', data);
			},
			// Funciones MisCuotas
			misCuotas_showDocumentsChild(data){
				this.$emit('misCuotas_showDocumentsParent', data);
			},
			// Funciones Mis Documentos
			generatePDFChild(data){
				this.$emit('generatePDFParent', data);
			},
			// Funciones Mis Mandatos
			genPDFChild(data){
				this.$emit('genPDFParent', data);
			},
			// Funciones Mis Declaraciones
			declaraciones_genPDFChild(data){
				this.$emit('declaraciones_genPDFParent', data);
			},
			// Funciones Mis Documentos - Sitio Solicitante
			solicitanteMisDocumentos_genPDFChild(data){
				this.$emit('solicitanteMisDocumentos_genPDFParent', data);
			},
			solicitanteMisDocumentos_generatePDFChild(data){
				this.$emit('solicitanteMisDocumentos_generatePDFParent', data);
			},
			// Funciones Mis Empresas - Sitio Solicitante
			solicitanteMisEmpresas_editChild(data){
				this.$emit('solicitanteMisEmpresas_editParent', data);
			},
			solicitanteMisEmpresas_solcilitarChild(data){
				this.$emit('solicitanteMisEmpresas_solcilitarParent', data);
			},
			//funcion tabla empresa
			misEmpresas_dropChild(data){
				this.$emit('misEmpresas_dropParent', data);
			},
			showFeesChild(data){
				this.$emit('showFeesParent', data);
			},
			anularPendingPaymentChild(data){
				this.$emit('anularPendingPaymentParent', data)
			},
			editCountChild(data){
				this.$emit('editCountParent', data);
			},
			selectedCountChild(data){
				this.$emit('selectedCountParent', data);
			},
			changeCountChild(data){
				this.$emit('changeDefaultAccountParent', data);
			},
			eliminarRespaldoChild(data){
				this.$emit('eliminarRespaldoParent', data);
			},
			descargarRespaldoChild(data){
				this.$emit('descargarRespaldoParent', data);
			}
		},
		components: {
			ProjectStatus,
		},
	})
	export default class SideBarPanel extends Vue {}
</script>

<style lang="scss" scoped>
	.rc-btnTable{
		color: $rc-prim-n1;
		margin-left:1px; 
		margin-right:1px;
		&:hover{
			color: darken($rc-prim-n1, $amount: 10);
		}
		&:disabled{
			color: $rc-escala2 !important;
		}
	}
</style>
