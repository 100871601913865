




































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































	import { Component, Vue } from "vue-property-decorator";
	import { TitleTemplateDashboard, SelectedCountry, RedCapital } from "@/configuration";
	import RegistroCuentaBancaria from "@/views/factoring/cuenta/RegistroCuentaBancaria.vue";
	// @ts-ignore
	import { Tabs, TabItem } from 'vue-material-tabs';
	import { mapState } from "vuex";
	import API from "@/api";
	import { EventBus } from "@/main";
	//@ts-ignore
	import { VueDatePicker } from '@mathieustan/vue-datepicker';
	import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';
	import BusinessSelect from "@/components/dashboard/BusinessSelect.vue";
	import ShowLoading from "@/components/panel/ShowLoading.vue";
	import Contact from '../Contact.vue';
	import AlertaAccion from '@/components/alertas/AlertaAccion.vue';
	
	var moment = require("moment");

	@Component({
		metaInfo: {
			title: "Anticipar facturas",
			titleTemplate: TitleTemplateDashboard,
			htmlAttrs: {
				// @ts-ignore
				lang: RedCapital[SelectedCountry].Lang,
			},
		},
		computed: {
			...mapState(["user","setOpenMenuPanel", "user_valido", "businesSelected"]),
			uploadDisabled() {
				return this.$data.files.length === 0;
			},
			setOpenMenuPanel(){
				return this.$store.state.openMenuPanel;
			},
			cotizable() {
				// @ts-ignore
				return (item) => {
					// @ts-ignore
					return !this.isCotizable(item);
				};
			},
			setNumberFormat(){
				// @ts-ignore
				return (monto_total) => {
					// @ts-ignore
					return monto_total.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
				};
			},
			async loadInfo(){
				if (this.$route.params.radius != null){
					this.$data.radios = this.$route.params.radius.toString()
				}
				if(this.$store.state.businesSelected != null ){
					// @ts-ignore
					this.listarFacturasConfirming()
				}
				API.factoring.lista_parametros_cotizacion() 
					.then((resParametros: any) => {
						this.$data.parametros_globales_cotizacion = resParametros.data;
						API.getBusiness()
							.then((resBussines: any)=> {
								if(resBussines.length == 0){
									//EventBus.$emit("empresa", true);
									this.$data.sinempresas = true;
									this.$data.datos = true;
								}
								else{
								API.factoring.get_cuentas(this.$store.state.businesSelected.id)
									.then((resCuentas:any) =>{
										this.$data.cuentasExistentes = resCuentas.mis_cuentas.length;
										let cuentas = resCuentas.mis_cuentas.filter((el:any) => el.validado != 0);
										this.$data.contadorCuentasb = cuentas.length;
										if(this.$data.contadorCuentasb == 1){
											this.$data.cuenta_id = cuentas[0].id
										}
											API.getBanks()
												.then((resBanks:any) =>{
													this.$data.getBanks = resBanks;
														cuentas.forEach((cuenta: any)=>{
														var banco = this.$data.getBanks.filter((banco: any)=> banco.id == cuenta.banco_id)
														cuenta.nombre_banco = banco;
														cuenta.nombre= `${cuenta.nombre_banco[0].nombre} ${cuenta.numero}`;
													})
													this.$data.cuentas_bancarias = cuentas;
												})
									});

								}
							})
					})
					API.getBusiness()
					.then((resBusiness: any) => {
						this.$data.staticBusiness = resBusiness;
					})
					.catch((err: any) => {});
				setTimeout(()=>{
					this.$data.isBeforeMount = true;
				},3000)

				this.$data.monedas = await API.getMonedas();
			}
		},
		name:'BeforeFacture',
		props:['radius'],
		data() {
			return {
				nocotizar:false,
				crearcuenta:false,
				cuentainvalida:false,
				cuentas_b:false,
				contadorCuentasb:0,
				cuentasExistentes: 0,
				isMobile: false,
				buscarFacturasEmitidas: "",
				buscarCargaManualFacturas: "",
				isLoadingSiguiente: false,
				isLoadingObtenerFacturasSii: false,
				isBeforeMount: false,
				activeDate:false,
				searchInput: null,
				searchInput2: null,
				loadingDTE:false,
				respaldo_fecha:{},
				fecha_vencimiento:'',
				modal_fechas:false,
				modal:false,
				companySelected: 0,
				validbtn:false,
				id_operacion_cotizacion:'',
				bloquearbtn:false,
				archivo_resp:{
					cotizacion_id:'',
					add_archivo_respaldo:{
						name:''
					},
					nombre_archivo_respaldo: '',
					description_archivo_respaldo:'',
				},
				archivos_respaldo:[],
				archivos_:false,
				documents_:false,
				registro_cuenta:'',
				alertaCargaPDf:false,
				disabledCount: 0,
				dates_parametrs:{
					cond_min_fech_venc:''
				},
				radios:"0",
				menu2:false,
				name:[],
				folio:[],
				filters: {
					name: [],
					folio:[]
				},
				simulacion:false,
				errores_simulacion:[],
				facturas_alert:false,
				error_facturas_fechas:[],
				origen: "AnticipoFactura",
				info: "",
				info_cotizacion:false,
				date: "",
				date2: "",
				steps: 1,
				steps2: 1,
				password_certidicado:null,
				datos: false,
				idsfacturas:[],
				idsestado:[],
				dateFormatted: null,
				dateFormatted2: null,
				pagination: {
					sortBy: "name",
				},
				selected: [],
				selected2:[],
				selected3:[],
				selected4:[],
				selectedceder:[],
				facturas_ceder:[],
				select_step_two:[],
				titlebtn: 'Seleccionar empresa',
				cheque:false,
				num_factur:0,
				total_factur:0,
				business:null,
				dialog2:null,
				dialog3:null,
				staticBusiness:"",
				selectedB: "",
				respaldo_selected:[],
				files:[],
					pfx:[],
					filesxls:[],
				errors:null,
				dialog:false,
				tabs: 'tab-1',
				password: "",
				c_password: "",
				isReady: false,
				montos: {},
				// TODO: volver a TRUE al terminar de modificar input
				contenido: false,
				showPassword: false,
				contanidovinculado: true,
				context: null,
				chart: null,
				facturas_subidas:[],
				showPassword3: false,
				datos_salida:null,
				file_pdf: "",
				file_xml: "",
				sinempresas: false,
				lista_parametros_cotizacion:{
						facturas:[]
				},
				parametros_globales_cotizacion:{
					monto_minimo:0
				},
				cuentas_bancarias:[],
				cuenta_id:null,
				
				getBanks:[],
				headers: [
					{
						text: "Serie",
						align: "center",
						sortable: false,
						value: "serie"
					},
					{
						text: "Folio",
						align: "center",
						sortable: false,
						value: "folio"
					},
					{
						text: "RUC Pagador",
						align: "center",
						sortable: false,
					},
					{
						text: "Nombre Pagador",
						align: "center",
						sortable: false,
						value: "deudor.nombre"
					},
					{
						text: "Fecha Emisión",
						align: "center",
						sortable: false,
					},
					{
						text: "Fecha Vencimiento",
						align: "center",
						sortable: false,
					},
					{
						text: "Monto Factura",
						align: "center",
						sortable: false,
					},
					{
						text: "Moneda",
						align: "center",
						sortable: false,
						value: "moneda",

					}
				],
				headers3: [
					{
						text: "Serie",
						align: "center",
						sortable: false,
					},
					{
						text: "Folio",
						align: "center",
						sortable: false,
					},
					{
						text: "RUC Pagador",
						align: "center",
						sortable: false,
					},
					{
						text: "Nombre Pagador",
						align: "center",
						sortable: false,
					},
					{
						text: "Fecha Emisión",
						align: "center",
						sortable: false,
					},
					{
						text: "Fecha Vencimiento",
						align: "center",
						sortable: false,
					},
					{
						text: "Monto",
						align: "center",
						sortable: false,
					},
					{
						text: "Moneda",
						align: "center",
						sortable: false,
					},
				],
				rules: {
					notEmpty: [(v: any) => !!v || "Ingrese un valor"],
					password: [(v: any) => !!v || "La contraseña es requerida"],
				},
				deudoresList: [],
				deudor: null,
				monedas: [],
				moneda_id: null,
				tipo_marca_id: null,
				cuentas_tipo_moneda: [],
				infoFacturas: {
					numFacturas_soles: 0,
					numFacturas_dolares: 0,
					monto_soles: 0,
					monto_dolares: 0
				},
				mensajeAnticipo: '',
				loadFacturasBtn: false,
				allowedDatesArray: [],
				fechas_invalidas:false
			};
		},
		async beforeMount() { 
			// @ts-ignore
			if(this.user_valido){
				// @ts-ignore
				this.loadInfo();
			}
			else{
				this.$data.dialog = true;
				
				//@ts-ignore
				if(this.businesSelected != null){
					//@ts-ignore
					API.factoring.sumaFacturasDTE(this.businesSelected.id).then((res)=> {
	
						const { facturas_soles, facturas_dolares, monto_soles, monto_dolares } = res.data;
	
						this.$data.infoFacturas.numFacturas_soles = facturas_soles;
						this.$data.infoFacturas.numFacturas_dolares = facturas_dolares;
						this.$data.infoFacturas.monto_soles = monto_soles;
						this.$data.infoFacturas.monto_dolares = monto_dolares;
	
						const numFacturasSoles = this.$data.infoFacturas.numFacturas_soles;
						const numFacturasDolares = this.$data.infoFacturas.numFacturas_dolares;
						const montoSoles = this.$data.infoFacturas.monto_soles;
						const montoDolares = this.$data.infoFacturas.monto_dolares;
	
						if (numFacturasSoles > 0 && numFacturasDolares > 0) {
						this.$data.mensajeAnticipo = `Tienes ${numFacturasSoles} facturas con un monto total de ${montoSoles} y <br> ${numFacturasDolares} con un monto total de ${montoDolares} para pago.`;
						} else if (numFacturasSoles > 0 && numFacturasDolares === 0) {
						this.$data.mensajeAnticipo = `Tienes ${numFacturasSoles} facturas con un monto total de ${montoSoles} para pago.`;
						} else if (numFacturasSoles === 0 && numFacturasDolares > 0) {
						this.$data.mensajeAnticipo = `Tienes ${numFacturasDolares} facturas con un monto total de ${montoDolares} para pago.`;
						} else {
							this.$data.mensajeAnticipo = '';
						}
	
						this.$data.dialog = false;
						
					}).catch(() => {
						//cambiar esto
						console.log('error');
						this.$data.dialog = false;
					})
				}
				else{
					this.$data.dialog = false;
				}

			}
		},
		mounted(){
			// @ts-ignore
			this.onResize();
			// @ts-ignore
			window.addEventListener("resize", this.onResize);
		},
		async created() {
			// @ts-ignore
			if(this.user_valido){
				// @ts-ignore
				window.addEventListener('unload', this.getTranckig('unload'));
			}
		},
		watch: {
			async companySelected(val) {
				// @ts-ignore
				await this.loadCompanyCreditRequests(val);
			
			},
			fecha_vencimiento(fecha){
				var index = this.$data.select_step_two.indexOf(this.$data.respaldo_fecha)
				this.$data.select_step_two[index].fecha_vencimiento = fecha;
			},
			folio(v){
				if(v == 0){
						this.$data.selected = this.$data.selected.concat(this.$data.respaldo_selected)
				}
			},
			name(v){
				if(v == 0){
						this.$data.selected = this.$data.selected.concat(this.$data.respaldo_selected)
						
				}
			},
			cuenta_id(v){
				v != null ? this.$data.validbtn = true : this.$data.validbtn = false
			},
			registro_cuenta(v){
				this.$data.cuenta_id = v.cuenta;
				this.$data.cuentas_b = false;
				//@ts-ignore
				this.cotizar3()
			},
			async radios(v){
				if(v == "0"){
					// @ts-ignore
					await this.listarFacturasConfirming();
					this.$data.tipo_marca_id = 3;
				}
				else if(v == "1"){
					// @ts-ignore
					await this.listarFacturasProntoPago();
					this.$data.tipo_marca_id = 2;
				}
				else if(v == "2"){
					// @ts-ignore
					await this.listarFacturasFactoring();
					this.$data.tipo_marca_id = 1;
				}
				
			},
			selected(v) {
				if (v.length > 0) {
					
					this.$data.num_factur = v.length;
					this.$data.total_factur = v
						.map((monto: any) => {
							return parseFloat(monto.monto_original);
						})
						.reduce((a: any, b: any) => {
							return parseFloat(a) + parseFloat(b);
						});

					var fact: any = [];
					v.forEach((el: any) => {
						let idfac = { id: "" };
						idfac.id = el.id;
						fact.push(idfac);
					});
					this.$data.lista_parametros_cotizacion.facturas = fact;
				} else {
					this.$data.num_factur = 0;
					this.$data.total_factur = 0;
				}
			},
			selected2(v) {
				if (v.length > 0) {
					this.$data.num_factur = v.length;
					this.$data.total_factur = v
					.map((monto: any) => {
						return parseFloat(monto.monto_original);
					})
					.reduce((a: any, b: any) => {
						return parseFloat(a) + parseFloat(b);
					});
					this.$data.activeDate = true;
					var fact: any = [];
					v.forEach((el: any) => {
						let idfac = { id: "" };
						idfac.id = el.id;
						fact.push(idfac);
					});
					this.$data.lista_parametros_cotizacion.facturas = fact;
				}
				else{
					this.$data.activeDate = false;
				}
			},
			business(v){
				this.$data.staticBusiness.forEach((el:any,i:any)=>{
					el.id == v ? this.$data.selectedB = el : ''
				})
				if(v == 0){
						this.$data.titlebtn = 'Crear'
						this.$store.commit('setBusinessSelected',null)
					//  this.$router.push('/panel-facto/createbussiness')
				} else{
						this.$store.commit('setBusinessSelected',this.$data.selectedB)
						this.$data.titlebtn = 'Seleccionar empresa'
				}
			},
			user_valido(v){
				//@ts-ignore
				if(this.user_valido){
					// @ts-ignore
					this.loadInfo();
				}
			}
		},
		methods: {
			onResize() {
				if (window.innerWidth < 1263){
					this.$data.isMobile = true;
				}
				else{
					this.$data.isMobile = false;
				}
			},
			volverAtras(){
				if(this.$data.steps == 2){
					if(this.$data.datos_salida != null){
						this.$data.datos_salida = null;
					}
				}
				this.$data.steps -= 1;
			},
			filtered2() {
				var filtroFascturas = [];
				this.$data.selected = this.$data.respaldo_selected;
				if(this.$data.name.length > 0 || this.$data.folio.length > 0 ){
					filtroFascturas =  this.$data.facturas_subidas.filter((i: any) => {
					return (
							this.$data.name.includes(i.deudor.nombre) ||
							this.$data.folio.includes(i.folio)
						)
					}) 
					let facturasFinales = this.$data.selected.concat(filtroFascturas)
					let unique = [...new Set(facturasFinales)];
				return unique

				}else {
					return []
				}
				
			},
			async actualizar_fecha_vencimiento(item){
				this.$data.respaldo_fecha={};
				//@ts-ignore
				await this.getAllowedDates(item.id);
				
				this.$data.modal_fechas= true;
				var fechaActual
				// Obtener el formato YYYY-MM-DD de la fecha actual
				//@ts-ignore
				this.$data.fecha_vencimiento = this.detectFormatDate(item.fecha_vencimiento);
				this.$data.respaldo_fecha = item;
			},
			detectFormatDate(date){
				const regex = /^\d{4}-/;

				if (regex.test(date)) {
					return date;
				} else {
					return moment(date, 'DD-MM-YYYY').format("YYYY-MM-DD");
				}
			},
			eliminarFactura(factura){
				var index = this.$data.select_step_two.indexOf(factura)
				this.$data.select_step_two.splice(index, 1);
			},
			guardar(event,factura){
				if(event){
					this.$data.respaldo_selected.push(factura)
				}else{
					var index = this.$data.respaldo_selected.indexOf(factura)
					this.$data.respaldo_selected.splice(index, 1);
				}
			},
			documents_close(){
				EventBus.$emit("alerts", false,'¡Atención!','')
				this.$data.documents = false;
				this.$data.info_cotizacion = true;
				setTimeout(()=>{
					this.$router.push('/panel-facto/ceder-facturas')
				},2000)
			},
			registrar_Archivos(){
				EventBus.$emit("alerts", false,'¡Atención!','')
				this.$data.documents = false;
				this.$data.documents_ = false;
				this.$data.info_cotizacion = true;
				setTimeout(()=>{
					this.$router.push('/panel-facto/ceder-facturas')
				},2000)
			},
			removeFilerespaldo(file,event) {
				event.preventDefault();
				this.$data.archivos_respaldo = this.$data.archivos_respaldo.filter((f: any) => {
					return f.add_archivo_respaldo != file;
				});
			},
			fileUpload_filesRespaldo() {
				// @ts-ignore
				if (this.$refs.pdfjpg.files.length > 0) {
					// @ts-ignore
					this.$data.archivo_resp.add_archivo_respaldo = this.$refs.pdfjpg.files[0];
				}
			},
			adds_respaldo(){
				this.$data.dialog = true;
				this.$data.archivo_resp.cotizacion_id = this.$data.id_operacion_cotizacion;
				this.$data.archivos_respaldo.push(this.$data.archivo_resp)
				this.$data.archivos_ = false;
				var formData = new FormData();
			
					formData.append('cotizacion_id',this.$data.archivo_resp.cotizacion_id)
					formData.append('nombre',this.$data.archivo_resp.nombre_archivo_respaldo)
					formData.append('descripcion',this.$data.archivo_resp.description_archivo_respaldo)
					formData.append('archivo',this.$data.archivo_resp.add_archivo_respaldo)
			
				API.factoring.subir_respaldo_cotizacion(formData)
				.then((res: any) => {
								this.$data.archivo_resp = {
									add_archivo_respaldo:{
										name:''
									},
									nombre_archivo_respaldo: '',
									description_archivo_respaldo:''
								}
							var indexArchivos = this.$data.archivos_respaldo.length -1
							this.$data.archivos_respaldo[indexArchivos].cargado = true;
							EventBus.$emit("snack-success", res.msg);
							this.$data.dialog = false;

							setTimeout(() => {
								EventBus.$emit("alerts", false,'¡Atención!','')
							},1000)

						//@ts-ignore
					this.$refs.form_respaldo.reset();
						})
						.catch((e) => {
							var indexArchivos = this.$data.archivos_respaldo.length -1
							this.$data.archivos_respaldo[indexArchivos].cargado = false;
							this.$data.dialog = false;
							this.$data.archivo_resp = {
									add_archivo_respaldo:{
										name:''
									},
									nombre_archivo_respaldo: '',
									description_archivo_respaldo:''
								}
							//@ts-ignore
						this.$refs.form_respaldo.reset();
							EventBus.$emit("alertsError", true,'¡Atención!',"Error al cargar archivos de respaldo")
							EventBus.$emit("snack-error", "Error al cargar archivos de respaldo");
					})
			},
			show_respaldo(){
				this.$data.archivos_ = true;
			},
			addxml(){
				// @ts-ignore
				this.$refs.facturasuploadxml.click();
			},
			df(id){
				//@ts-ignore
				this.$refs[id].$el.parentElement.children[2].classList.add('ocultar')
			},
			date_emision(date){
				return moment(new Date()).subtract(this.$data.dates_parametrs.dias_diferencia,'d').format("YYYY-MM-DD") > moment(date.trim(), "YYYY-MM-DD").format("YYYY-MM-DD")
			},
			async dates(){
				const dates = await  API.factoring.date_parametros_onboarding()
				this.$data.dates_parametrs = dates.parametro
			}, 
			formatDate (date) {
				return moment(date).format('DD-MM-YYYY')
			},
			Limpiar(){
				this.$data.name = '';
			},
			fileUploadxml2(event){
				if (event.target.files.length > 0) {
					let filess = event.target.files
					if (!filess) return;
					[...filess].forEach((f) => {
						this.$data.files.push(f);
					});
				}
			},
			addfactura(){
				// @ts-ignore
				this.$refs.uploadxml.click();
			},
			estadoFacturasPanel(){
				this.$router.push('/panel-facto/mis-operaciones')
			},
			async getUltimoPaso(){
				let origen = 'AnticipoFactura'
				//TODO: registrar esta api
				const ultimoPaso = await API.factoring.ultimoPasoEmpresa(this.$store.state.businesSelected.id,origen)
				return ultimoPaso
			},
			async getTranckig(event){
				if(event == 'unload'){
					// @ts-ignore
					var lastStep = await this.getUltimoPaso()
					if(lastStep.data){
						let fact = JSON.parse(lastStep.data.json)
					}
				}
			},
			registrarPasos(origen,variable,id_empresa,info,facturas){
				API.factoring.tranckingOnboarding(origen,this.$store.state.businesSelected.id,this.$store.state.ip,variable,info,facturas)
				.then((res)=>{})
			},
			valestado(v){
				if((v == 4) || (v == 5) || (v == 6)){
					return false;
				}else {
					true
				}
			},
			fileUploadxml(event,item) {
				// if (event.target.files.length > 0) {
				// 	// @ts-ignore
				// 	this.$data.file_xml = event.target.files[0];
				// 	if (this.$store.state.businesSelected != null) {
				// 		API.factoring.subir_xml_factura(item.id,this.$data.file_xml)
				// 			.then((res: any) => {
				// 				if(res.error == 0){
				// 					EventBus.$emit("snack-success", "Archivo cargado");
				// 					var datos = this.$data.selected2.find((el: any)=> item.id == el.id )
				// 					var index = this.$data.selected2.indexOf(datos)
				// 					 this.$data.selected2[index].archivo_xml = 1;

				// 					EventBus.$emit("alerts", true,'¡Atención!',"Facturas cargadas correctamente")
				// 					EventBus.$emit("snack-success", "Factura cargada con exito");
				// 					this.$data.dialog = false;
				// 				}else{
				// 					res.mensaje 
				// 					EventBus.$emit("alertsError", true,'¡Atención!',res.mensaje)
				// 					EventBus.$emit("snack-error", "Error al cargar la Factura");
				// 				}
								
				// 			})
				// 			.catch((e) => {
				// 				this.$data.file_xml = "";
				// 				EventBus.$emit("alertsError", true,'¡Atención!',"Error al cargar Facturas")
				// 				EventBus.$emit("snack-error", "Error al cargar la Factura");
				// 				this.$data.dialog = false;
				// 			});
				// 	} else {
				// 		EventBus.$emit("snack-error", "Debe seleccionar una Empresa");
				// 		this.$data.dialog = false;
				// 	}
				// }
			},
			displayRef(event,ref) {
				// @ts-ignore
				this.$refs[ref].click()
			},
			displayRef2(ref2) {
				// @ts-ignore
				this.$refs[ref2].click()
			},
			toggleAll () {
				var filtroFascturas = [];
				if(this.$data.selected.length != this.$data.facturas_subidas.length) {
					this.$data.selected = this.$data.respaldo_selected;
					if(this.$data.name.length > 0 || this.$data.folio.length > 0 ){
						filtroFascturas =  this.$data.facturas_subidas.filter((i: any) => {
							return (this.$data.name.includes(i.deudor.nombre) || this.$data.folio.includes(i.folio))
						}) 
						this.$data.selected = [...new Set(filtroFascturas)];
					} else {
						//@ts-ignore
						if(this.$data.selected.length == this.$data.facturas_subidas.filter(factura => this.isCotizable(factura)).length &&  this.$data.respaldo_selected.length == this.$data.facturas_subidas.filter(factura => this.isCotizable(factura)).length){
							this.$data.selected = [];
							this.$data.respaldo_selected = [];
						}
						else{
							this.$data.selected = [];
							this.$data.respaldo_selected = [];
							//@ts-ignore
							this.$data.selected = [...new Set(this.$data.facturas_subidas.filter(factura => this.isCotizable(factura)))];
							//@ts-ignore
							this.$data.respaldo_selected =  [...new Set(this.$data.facturas_subidas.filter(factura => this.isCotizable(factura)))];
						} 
						
					}
				} else {
					this.$data.selected = [];
					this.$data.respaldo_selected = [];
				}
			},
			toggleAll2 () {
				if (this.$data.selected2.length != this.$data.select_step_two.length) { 
					this.$data.selected2 = [];
					this.$data.selected2 = this.$data.select_step_two;
				}
				else {
					this.$data.selected2 =  []
				}
			},
			toggleAll3 () {
				if (this.$data.selected4.length) {
					this.$data.selected4 = []
				} else {
					this.$data.selected4 = this.$data.selected2.slice()
				}
			},
			toggleAllceder () {
				if(this.$data.selectedceder.length != this.$data.facturas_subidas.length) {
					this.$data.selectedceder = [];
					//@ts-ignore
					this.$data.facturas_subidas.filter(factura => !this.isCotizable(factura)).forEach((item: any)=> {
						// @ts-ignore
						const valfecha = moment(new Date()).subtract(this.$data.dates_parametrs.dias_diferencia,'d').format("YYYY-MM-DD") > moment(item.fecha_emision.trim(), "DD-MM-YYYY").format("YYYY-MM-DD")
						if(!valfecha) {
							this.$data.selectedceder.push(item);
						} 
					});
				} else {
					this.$data.selected = [];
				}
			},
			changeSort (column) {
				if (this.$data.pagination.sortBy === column) {
					this.$data.pagination.descending = !this.$data.pagination.descending
				} else {
					this.$data.pagination.sortBy = column
					this.$data.pagination.descending = false
				}
			},
			async listarFacturasConfirming(){
				this.$data.loadingDTE = true;
				this.$data.dialog = true;
				this.$data.facturas_subidas = []
				
				API.factoring.listarFacturasConfirming(this.$store.state.businesSelected.id)
				.then((res: any) => {
					this.$data.facturas_subidas = res.data;
					this.$data.loadingDTE = false;
					this.$data.dialog = false;
				}).catch((err) => { 
					this.$data.dialog = false; 
					this.$data.loadingDTE = false;
				})
			},
			async listarFacturasProntoPago(){
				this.$data.loadingDTE = true;
				this.$data.dialog = true;
				this.$data.facturas_subidas = [];

				API.factoring.listarFacturasDTE(this.$store.state.businesSelected.id)
				.then((res:any) => {
					this.$data.facturas_subidas = res.facturas;
					this.$data.deudoresList = res.deudores;
					this.$data.loadingDTE = false;
					this.$data.dialog = false;
				}).catch(() => {
					this.$data.dialog = false; 
					this.$data.loadingDTE = false;
				})
			},
			cotizar(n) {
				// loading btn siguiente
				this.$data.isLoadingSiguiente = true;
				this.$data.select_step_two = [];
				this.$data.idsfacturas=[]
				this.$data.idsestado=[]
				this.$data.selected.forEach((el:any)=>{
					this.$data.idsfacturas.push(el.id)
					this.$data.idsestado.push(1)
					this.$data.select_step_two =  this.$data.selected;
					var todate = new Date();
					//@ts-ignore
					this.$data.date = moment(todate,"'YYYY-MM-DD").add(this.$data.dates_parametrs.cond_min_fech_venc,'d').format('YYYY-MM-DD')
				})
				API.factoring.actualizar_estados_factura(this.$store.state.businesSelected.id,this.$data.idsfacturas,this.$data.idsestado)
					.then((res: any) => {
						var facturas = res.data;
						if(this.$data.radios == '3'){
							this.$data.selected = [];
							facturas.map((el:any) => {
								var fecha = moment(new Date).format('YYYY-MM-DD');
								el.fecha_vencimiento = fecha;
								this.$data.selected.push(el)
							})
							this.$data.selected2 = this.$data.selected;
						}else{
							this.$data.selected2 = this.$data.selected;
						}        
						this.$data.steps = n;
						//@ts-ignore
						if(this.$data.radios != 2){
							//@ts-ignore
							this.simular_cotizacion(n);
						}
						let variable = "paso 2";
						//@ts-ignore
						this.registrarPasos(this.$data.origen,variable,this.$store.state.businesSelected.id,this.$data.info,this.$data.selected)
						this.$data.isLoadingSiguiente = false;
					}).catch((err: any) =>{
						this.$data.isLoadingSiguiente = false;
					})
			},
			cotizar2(n) {
				this.$data.isLoadingSiguiente = true;
				this.$data.error_facturas_fechas= []
				var facturas_subidas: any = [];
				this.$data.select_step_two.forEach((el: any) => {
					let idfac = { 
						id: "", 
						fecha_vencimiento: "" 
					}
					idfac.id = el.id;
					idfac.fecha_vencimiento = el.fecha_vencimiento;
					//facturas.push(el.id, el.fecha_vencimiento)
					facturas_subidas.push(idfac);
				});
				API.factoring
				.actualizarFechaVencimiento(facturas_subidas)
				.then((res: any) => {
					if(res.status == 201){
						const fechas = res.data;
					
						this.$data.select_step_two.forEach((el: any) => {
							const factura  = fechas.find((item:any) => item.id == el.id);
							el.fecha_actualizada = factura.fecha_actualizada;
						});

						this.$data.fechas_invalidas = true;
						this.$data.isLoadingSiguiente = false;

					}else{
						this.$data.select_step_two.forEach((el: any) => {
							el.fecha_actualizada = true;
						});
						this.$data.steps = n;
						this.$data.selected3 = res.data
						this.$data.selected4 = this.$data.selected3
						this.$data.isLoadingSiguiente = false;

						//@ts-ignore
						this.simular_cotizacion(n);
					}
				})
				.catch((err: any)=>{
					this.$data.isLoadingSiguiente = false;
				})
			},
			simular_cotizacion(step){
				this.$data.nocotizar = false;
				// this.$data.alertaCargaPDf = true;
				var fact: any = [];
					this.$data.selected2.forEach((el: any) => {
					fact.push({"id":el.id})
				});
				this.$data.lista_parametros_cotizacion.facturas = fact;
				this.$data.lista_parametros_cotizacion.empresa_id = this.$store.state.businesSelected.id;
				this.$data.lista_parametros_cotizacion.tipo_marca = this.$data.tipo_marca_id;
				this.$data.lista_parametros_cotizacion.moneda_id = this.$data.moneda_id;
				this.$data.lista_parametros_cotizacion.api_key= 'key_onboarding_prod_fnPqT5xQEi5Vcb9wKwbCf65c3BjVGyBB';
				API.factoring
				.simularCotizacion(this.$data.lista_parametros_cotizacion)
				.then((res: any) => {
					if(res.status == 3){
						EventBus.$emit("alertsError", true,'¡Atención!', `${res.msg} `)
						setTimeout(()=>{
							setTimeout(()=>{
								this.$router.push('/panel-facto/estadisticas')
								EventBus.$emit("alertsError", false,'¡Atención!','')
							},1500)
						},2000)
					}
						
					if(res.errors){
						this.$data.errores_simulacion.push(res.errors)
						this.$data.datos_salida = null;
					}
					
					this.$data.steps = step;
					this.$data.datos_salida = res;
				})
				.catch((err) => {
					EventBus.$emit("alertsErrorContacto", true,'¡Atención!', `Ha ocurrido un error con la cotizacion, por favor intente más tarde o pongase en contacto con nosotros`);
					this.$data.nocotizar = true;
				});
			},
			addcuenta_bancaria(){
				EventBus.$emit("registro_cuentas_bancarias", true)
			},
			cuentas(){
				this.$data.isLoadingSiguiente = true;
				//@ts-ignore
				this.$data.cuentas_tipo_moneda = this.filterCuentasBancarias(this.$data.moneda_id);

				if(this.$data.cuentas_tipo_moneda.length > 0){
					if(this.$data.cuentas_tipo_moneda.length == 1){
						this.$data.cuenta_id = this.$data.cuentas_tipo_moneda[0].id; 
						//@ts-ignore
						this.cotizar3()
					}else if(this.$data.cuentas_tipo_moneda.length > 1){
						this.$data.cuentas_b = true;
					}
				}else{
					this.$data.crearcuenta = true;
				}

				this.$data.isLoadingSiguiente = false;
			},
			cotizar3() {
					this.$data.isLoadingSiguiente = true;
					this.$data.validbtn = false;
					var acc1 = this.$data.selected2.map((el: any)=> parseInt(el.monto_original));
					var acc2 = acc1.reduce((acc:any,cur:any) => acc+cur );
					if(parseInt(acc2) >= this.$data.parametros_globales_cotizacion.monto_minimo){
						var fact: any = [];
						this.$data.selected2.forEach((el: any) => {
						var obj = {
							id: el.id
						}
							fact.push(obj)
						});
						this.$data.lista_parametros_cotizacion.facturas = fact;
						this.$data.lista_parametros_cotizacion.empresa_id = this.$store.state.businesSelected.id;
						this.$data.lista_parametros_cotizacion.api_key= 'key_onboarding_prod_fnPqT5xQEi5Vcb9wKwbCf65c3BjVGyBB';
						this.$data.lista_parametros_cotizacion.cuenta_id = this.$data.cuenta_id;
						this.$data.lista_parametros_cotizacion.tipo_marca = this.$data.tipo_marca_id;
						this.$data.lista_parametros_cotizacion.moneda_id = this.$data.moneda_id;
						this.$data.lista_parametros_cotizacion.valor_tasa = this.$data.datos_salida.cotizacion.tasa;
						
						API.factoring
						.crear_cotizacion(this.$data.lista_parametros_cotizacion)
						.then((res: any) => {

							if(res.data.status == 200){
								this.$data.id_operacion_cotizacion = res.data.operacion;
								this.$data.cuentas_b = false;

								EventBus.$emit("alerts", true, '¡Atención!', 'Cotización creada exitosamente')
								setTimeout(() => {
									this.$data.info_cotizacion = true;
									setTimeout(()=>{
										this.$router.push('/panel-facto/mis-operaciones')
									},3000)
									
									EventBus.$emit("alerts", false, '¡Atención!', '')
								},2000)
							}
							
							if(res.data.status == 422){
								EventBus.$emit("alertsError", true,'¡Atención!', `${res.data.errors}`)
								EventBus.$emit("snack-error", "Error al cotizar");
								this.$data.validbtn = true;
							}
							
							let variable = "Cotizar";
							//@ts-ignore
							this.registrarPasos(this.$data.origen,variable,this.$store.state.businesSelected.id,this.$data.info,this.$data.selected4);
							this.$data.isLoadingSiguiente = false;

						})
						.catch((err) => {
							EventBus.$emit("alertsErrorContacto", true,'¡Atención!',"Esta transacción no puede ser tramitada en el momento. Contáctanos.");
							EventBus.$emit("snack-error", "Error al cotizar");
							this.$data.isLoadingSiguiente = false;

						});
						}else {
							this.$data.facturas_alert = true
						}
			},
			isCotizable(factura) {
				
				if(this.$data.radios == '0'){
					return false; 
				}

				if(this.$data.radios == '2'){
					if(this.$data.moneda_id){
						let esCotizable = (factura.moneda_id == this.$data.moneda_id);
		
						return esCotizable;
					}
				}

				if(this.$data.deudor && this.$data.moneda_id){

					let esCotizable = (factura.moneda_id == this.$data.moneda_id) && (factura.deudor.rut == this.$data.deudor);

					return esCotizable;
				}

				else{
					return false;
				}

			},
			filterCuentasBancarias(moneda_id){
				return this.$data.cuentas_bancarias.filter((item:any) => item.moneda_id == moneda_id );
			},
			bussinessSelection() {
				this.$router.push("/panel-facto/empresas");
			},
			async listarFacturasFactoring(){
				this.$data.loadingDTE = true;
				this.$data.dialog = true;
				this.$data.facturas_subidas = [];

				API.factoring.listarFacturasFactoring(this.$store.state.businesSelected.id)
				.then((res:any) => {
					this.$data.facturas_subidas = res.facturas.map((factura:any) => {
						return { ...factura, fecha_actualizada: true };
					});

					this.$data.loadingDTE = false;
					this.$data.dialog = false;
				}).catch(() => {
					this.$data.dialog = false; 
					this.$data.loadingDTE = false;
				});
			},

			syncFacturas(){
				this.$data.loadFacturasBtn = true;
				
				API.getFacturasEmitidasSUNAT(this.$store.state.businesSelected.rut).
				then((response) => {
					if(response.status == 200){
						EventBus.$emit("snack-success", 'Sincronizando facturas');

						setTimeout(() => {
							this.$data.loadFacturasBtn = false;
						}, 300000); 

					}
					else{
						EventBus.$emit("snack-error", 'Ha ocurrido un error');
						this.$data.loadFacturasBtn = false;
					}
				}).catch(() => {
					EventBus.$emit("snack-error", 'Ha ocurrido un error');
					this.$data.loadFacturasBtn = false;
				});
			},
			allowedDates(val) {
				return this.$data.allowedDatesArray.includes(val);
			},
			async getAllowedDates(factura_subida_id) {
				this.$data.allowedDatesArray = [];

				let fechas = await API.obtenerPlazoFactura(factura_subida_id);
				fechas.data.map((item:any) => {
					this.$data.allowedDatesArray.push(item);
				})
			},

		},
		components: {
			ShowLoading,
			RegistroCuentaBancaria,
			VueDatePicker,
			BusinessSelect,
			Tabs,
			TabItem,
			AlertaAccion
		},
	})
export default class BeforeFacture extends Vue {}
