













































































import { Component, Vue } from "vue-property-decorator";
import {
  TitleTemplateDashboard,
  SelectedCountry,
  RedCapital,
} from "@/configuration";
import { mapState } from "vuex";
import API from "@/api";
// @ts-ignore
import ChartJS from "chart.js";
import { EventBus } from "@/main";
import Business from './Business.vue';

@Component({
  metaInfo: {
    title: "Mi panel",
    titleTemplate: TitleTemplateDashboard,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang,
    },
  },
  computed: {
    ...mapState(["user", "businesSelected", "setOpenMenuPanel"]),
	setOpenMenuPanel(){
		return this.$store.state.openMenuPanel;
	}
  },
  data() {
    return {
      businessData: [],
      monto_disponible: {
        monto_disponible : 0
      },
      empresas_vinculadas:0,
      usuariNoAuth:false,
    };
  },
  watch: {
    businesSelected(v: any){
      if(v){
        // @ts-ignore
        // this.cargarMonto();
        // @ts-ignore
        this.userEmpresaValid();
      }
    }
  },
  beforeMount() {
    if(!this.$store.state.businesSelected){
      // @ts-ignore
      this.business();
      
      // @ts-ignore
      this.userEmpresaValid();
    }
  },
  methods: {
    cargarMonto(){
      // TODO: PENDIENTE ESTA API
      API.factoring.sumaFacturas(this.$store.state.businesSelected.id)
        .then(res =>{
          this.$data.monto_disponible = res.data
        })
    },
    bienvenido() {
      this.$router.push("/contacto");
    },
    business() {
      API.getBusiness().then((res: any) => {
        this.$data.businessData = res;
        if (this.$data.businessData.length == 0) {
            EventBus.$emit("empresa", true);
          setTimeout(() => {
            this.$router.push("/panel-facto/empresas");
          
          }, 1000);
        } 
        else {
            if (this.$data.businessData.length == 1) {
              if(this.$data.businessData[0].user_id == this.$store.state.user.id){
                  this.$store.commit("setBusinessSelected", this.$data.businessData[0]);
              }
            } else if (this.$data.businessData.length > 1) {

              let lastBusiness = this.$data.businessData[this.$data.businessData.length - 1];
              this.$store.commit("setBusinessSelected", lastBusiness);
            }
        }
      });
    },
    userEmpresaValid(){
      //@ts-ignore
      if(this.businesSelected != null){
        //@ts-ignore
        API.factoring.validarEmpresaUsuario(this.businesSelected.id).then((response) => {
            let estado = response.data;
            this.$store.commit("setUserValid", estado);
          })
        }
    }
  },
})
export default class Stats extends Vue {}
