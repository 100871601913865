



























































































































































































    import { Component, Vue } from "vue-property-decorator";
    import { mapState } from "vuex";
    import { EventBus } from "@/main";
    import {
      TitleTemplateDashboard,
      SelectedCountry,
      RedCapital,
    } from "@/configuration";
    import API from "@/api";
    import TableResponsive from "@/components/table/TableResponsive.vue";
    
    @Component({
        computed: {
            ...mapState(["businesSelected"])
        },
        metaInfo: {
            title: "Mis datos",
            titleTemplate: TitleTemplateDashboard,
            htmlAttrs: {
            // @ts-ignore
            lang: RedCapital[SelectedCountry].Lang,
            },
        },
        data() {
            return {
                dialog: false,
                searchRespaldos: "",
                disabledButton: true,
                companySelected: null,
                empresasSincronizadas: [],
                respaldosData: [],
                tipoRespaldos:[],
                modal_agregar_respaldo: false,
                modal_eliminar_respaldo: false,
                respaldo:{
                    respaldo_id: null,
                    tabla_slug: null,
                    idx: null,
                    tipo_respaldo: null,
                    documento: null,
                    nombre_respaldo: null
                },
                tablaRespaldos: {
                    headers: [
                        {
                            text: "Nombre archivo",
                            align: "center",
                        },
                        {
                            text: "Tipo",
                            align: "center",
                        },
                        {
                            text: "Fecha carga",
                            align: "center",
                        },
                        {
                            text: "Eliminar",
                            align: "center",
                            sortable: false,
                        }
                    ],
                    msjEmpty: 'No existen registros',
                    rowsPerPage: {
                        txt: 'Registro por página',
                        itm: [10, 25, 50, { text: 'Todos', value: -1 }]
                    },
                },
                headers: [
                    {
                        // @ts-ignore
                        text: `Nombre archivo`,
                        align: "center",
                        value: "nombre",
                    },
                    {
                        // @ts-ignore
                        text: `Tipo`,
                        align: "center",
                        value: "tipo",
                    },
                    {
                        // @ts-ignore
                        text: `Fecha carga`,
                        align: "center",
                        value: "fecha_carga",
                    },
                    {
                        // @ts-ignore
						text: "Eliminar",
						align: "center",
						sortable: false,
					}
                ],
            }
        },
        async beforeMount(){
            // @ts-ignore
            this.$data.tipoRespaldos = await API.getTipoRespaldos("empresas");
            // @ts-ignore
            await this.getRespaldos();
        },
        methods: {
            isNumeric(num){
                return !isNaN(num)
            },
            fileUpload(event) {
                //@ts-ignore
                const file = event.target.files[0];
                if (file) {
                    this.$data.respaldo.documento = file;
                }
            },
            removeFile() {
                // @ts-ignore
                this.$refs.uploadFile.value = null
            },
            async getRespaldos() {
                try {
                    //@ts-ignore
                    let empresa_id = (this.businesSelected.id);
                    this.$data.respaldosData = await API.getRespaldosEmpresa(empresa_id);
                    /* Formateando Data */
                    let propsObjTableAll = {
                        style: `text-align: center;
                                font-size: 0.7rem;
                                font-weight: 500;
                                color: #8b8b8b`
                    }
                    let propsObjTableButtons = {
                        style: `display: flex;
                                justify-content: center;`
                    }
                    Array.from(this.$data.respaldosData, respaldo =>{
                        //@ts-ignore
                        respaldo.datosEnTabla;
                        // @ts-ignore
                        let objTable = {
                            nombreRespaldo:{
                                // @ts-ignore
                                value: respaldo.nombre,
                                label: 'Nombre archivo',
                                props: propsObjTableAll
                            },
                            tipoRespaldo:{
                                // @ts-ignore
                                value: respaldo.tipos.tipo,
                                label: 'Tipo',
                                props: propsObjTableAll
                            },
                            fechaCarga:{
                                // @ts-ignore
                                value: respaldo.fecha_creacion,
                                label: 'Fecha carga',
                                props: propsObjTableAll
                            },
                            acciones:{
                            // @ts-ignore
                            value: '',
                            label: 'Acciones',
                            props: propsObjTableButtons,
                            buttons: [{
                                disabled: true,
                                funcion: 'eliminarRespaldoChild',
                                icoFont: 'delete',
                                notOutline: true,
                                // @ts-ignore
                                data: respaldo
                            },{
                                disabled: true,
                                funcion: 'descargarRespaldoChild',
                                icoFont: 'download',
                                notOutline: true,
                                // @ts-ignore
                                data: respaldo
                            }],
                        },
                        }
                        //@ts-ignore
                        respaldo.datosEnTabla = objTable;
                    })
                } catch (error) {
                    console.error('Error al obtener respaldos:', error);
                }
            },
            agregarRespaldo(){
                this.$data.respaldo.tabla_slug = "empresas";
                //@ts-ignore
                this.$data.respaldo.idx = this.businesSelected.id;
                this.$data.respaldo.tipo_respaldo = '';
                this.$data.respaldo.documento = null;
                this.$data.modal_agregar_respaldo = true;
                //@ts-ignore
                this.removeFile();
            },
            agregar() {
                this.$data.disabledButton
                this.$data.dialog = true;
                API.guardarRespaldo(this.$data.respaldo)
                .then((res: any) => {
                    //@ts-ignore
                    this.removeFile();
                    EventBus.$emit("snack-success", res.message);
                    //@ts-ignore
                    this.getRespaldos();
                    this.$data.dialog = false;
                    this.$data.modal_agregar_respaldo = false;
                })
                .catch((error) => {
                    this.$data.dialog = false;
                    const response = error.response.data;
                    if (response.status == 419){
                        EventBus.$emit("snack-error", response.message);
                    } else if (response.status == 422){
                        let mensaje = '';
                        Object.values(response.data).forEach(element => {
                            mensaje += element + '' + ' ';
                        });
                        EventBus.$emit("snack-error", mensaje);
                    } else {
                        EventBus.$emit("snack-error", response.message);
                    }
                });
            },
            descargar_respaldos(respaldo) {
                API.descargarRespaldo(respaldo.respaldo_id)
                .then(response => {
                    const url = response.url;
                    const filename = response.filename;
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = filename;
                    link.target = '_blank';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                })
                .catch(error => {
                    const response = error.response.data;
                    EventBus.$emit("snack-error",  response.message);
                });
            },
            eliminar_respaldos(respaldo) {
                this.$data.respaldo.respaldo_id = respaldo.respaldo_id;
                this.$data.respaldo.nombre_respaldo = respaldo.nombre;
                this.$data.modal_eliminar_respaldo = true;
            },
            eliminar() {
                this.$data.dialog = true;
                API.eliminarRespaldo(this.$data.respaldo.respaldo_id)
                .then((res: any) => {
                    this.$data.dialog = false;
                    // @ts-ignore
                    this.getRespaldos();
                    this.$data.modal_eliminar_respaldo = false;
                    EventBus.$emit("snack-success", res.message);
                })
                .catch((error) => {
                    this.$data.dialog = false;
                    const response = error.response.data;
                    if (response.status == 419){
                        EventBus.$emit("snack-error", response.message);
                    } else if (response.status == 422){
                        let mensaje = '';
                        Object.values(response.data).forEach(element => {
                            mensaje += element + '' + ' ';
                        });
                        EventBus.$emit("snack-error", mensaje);
                    } else {
                        EventBus.$emit("snack-error", response.message);
                    }
                });
            }
        },
        watch: {
            '$data.respaldo': {
                deep:true,
                immediate:true,
                handler() {
                    const respaldo = this.$data.respaldo;
                    this.$data.disabledButton = (
                        respaldo.tabla_slug !== null &&
                        respaldo.idx !== null &&
                        respaldo.tipo_respaldo !== '' &&
                        respaldo.documento !== null
                    );
                }
            }
        },
        components: {
            TableResponsive,
        }
    })
    export default class Respaldos extends Vue {}
    